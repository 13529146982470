<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-group">
      <label>Select Boat</label>
      <select required v-model="newManifesto.sysfk_boat_manifesto" class="form-select">
        <option
          v-for="boat in loadedBoats"
          :key="boat.syspk_boat"
          :value="boat.syspk_boat"
        >
          {{ boat.name_boat }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <button class="btn btn-danger w-100" type="submit">Save Changes</button>
    </div>
  </form>
</template>
<script>
import manifestoService from "@/services/manifestoService";
import toastService from "@/services/toastService";
import { ref, computed } from "vue";
export default {
  props: {
    boats: {
      type: Object,
      required: true,
    },
    manifesto: {
      type: Object,
      required: true,
    },
  },
  emits: ["manifesto-updated"],
  setup(props, { emit }) {
    const newManifesto = ref(computed(() => props.manifesto));
    const loadedBoats = ref(props.boats);
    const handleSubmit = async () => {
      try {
        const response = await manifestoService.update({
          syspk_manifesto: newManifesto.value.syspk_manifesto,
          sysfk_boat_manifesto: newManifesto.value.sysfk_boat_manifesto,
        });
        if (response.data.success) {
          emit("manifesto-updated", newManifesto.value);
          toastService.success("Boat selection updated successfully.");
        } else {
          toastService.error(response.data.message);
        }
      } catch (err) {
        toastService.error("An error occured while processing the request");
      }
    };

    return {
      handleSubmit,
      newManifesto,
      loadedBoats,
    };
  },
};
</script>
