<template>
  <form @submit.prevent="handleFormSubmit">
    <div class="form-group mb-2">
      <label>Reason: </label>
      <textarea required class="form-control" v-model.trim="reason" rows="3"></textarea>
    </div>
    <div class="form-group">
      <button class="btn btn-danger w-100" type="submit">Confirm Revoke</button>
    </div>
  </form>
</template>

<script>
import apiService from "@/services/apiService";
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import store from "@/store";
import { ref } from "vue";
export default {
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
  },
  emits: ["revoke-success"],
  setup(props, { emit }) {
    const reason = ref("");
    const handleFormSubmit = async () => {
      const confirmed = await store.dispatch(
        "showConfirmModal",
        "Are you sure you want to revoke this booking?"
      );

      if (confirmed) {
        try {
          spinnerService.show();
          const response = await apiService.post(
            `v1/b/revoke/${props.bookingId}`,
            {
              message: reason.value,
            },
            true
          );
          if (response.data.success) {
            toastService.success("Action successful!");
            emit("revoke-success", props.bookingId);
          } else {
            toastService.error(response.data.message);
          }
        } catch (err) {
          console.log(err);
        } finally {
          spinnerService.hide();
        }
      }
    };
    return {
      reason,
      handleFormSubmit,
    };
  },
};
</script>
