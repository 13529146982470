<template>
  <div>
    <div v-if="!isProcessing">
      <div
        v-if="isActivationSuccessful"
        class="d-flex flex-column align-items-center justify-content-center min-vh-100"
      >
        <i class="bi bi-check-circle-fill display-1 text-success"></i>
        <h1 class="mt-3">Activation Successful</h1>
        <p class="lead">
          Your account has been activated successfully. You can now log in.
        </p>
        <router-link :to="{ name: 'home' }" class="btn btn-primary mt-3"
          >Return Home</router-link
        >
      </div>
      <div
        v-else-if="errmsg"
        class="d-flex flex-column align-items-center justify-content-center min-vh-100"
      >
        <i class="bi bi-exclamation-triangle-fill display-1 text-danger"></i>
        <h1 class="mt-3">Activation Failed</h1>
        <p class="lead">{{ errmsg }}</p>
        <button class="btn btn-secondary mt-3" @click="retryActivation">
          Retry Activation
        </button>
      </div>
    </div>
    <div v-else class="d-flex align-items-center justify-content-center min-vh-100">
      <div class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h1 class="mt-3">Processing Activation</h1>
        <p class="lead">Please wait while we activate your account.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import toastService from "@/services/toastService";
import { useStore } from "vuex";
export default {
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const isProcessing = ref(false);
    const errmsg = ref(null);
    const isActivationSuccessful = ref(false);
    const activate = async () => {
      try {
        isProcessing.value = true;
        isActivationSuccessful.value = false;
        errmsg.value = null;
        await store.dispatch("auth/activate", props.code);
        isActivationSuccessful.value = true;
      } catch (err) {
        toastService.error("An error occured while processing the request");
      } finally {
        isProcessing.value = false;
      }
    };
    const retryActivation = () => {
      activate();
    };
    onMounted(() => {
      activate();
    });
    return {
      isProcessing,
      errmsg,
      retryActivation,
      isActivationSuccessful,
    };
  },
};
</script>
