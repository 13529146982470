const apiUrl = process.env.VUE_APP_API_URL || 'https://localhost:7179/'; //'https://paldest-api.exodoustechsolutions.com/';

const getInitBookingConfig = ()=>{
    try{
       return JSON.parse(localStorage.getItem('bookingstate'))
    }catch{
        return null;
    }
}

const clearInitBookingConfig = ()=>{
       localStorage.removeItem('bookingstate');
}

const adminTellerRoles = ['admin', 'superadmin', 'attendant','teller']
const superAdminRoles = ['developer', 'superadmin']

export { apiUrl,getInitBookingConfig,clearInitBookingConfig,adminTellerRoles,superAdminRoles };
