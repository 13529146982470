<template>
  <div
    v-if="!showConfirmation"
    class="d-flex align-items-center justify-content-center min-vh-100"
  >
    <form
      class="p-4 bg-light rounded shadow-sm"
      style="min-width: 350px; max-width: 100%"
      @submit.prevent="handleSubmit"
    >
      <div class="text-center mb-4" style="margin-top: -105px">
        <router-link to="/">
          <img src="/favicon.ico" class="rounded-circle" height="150" width="150" />
        </router-link>
      </div>
      <h2 class="text-center mb-4">Signup</h2>

      <div class="form-group">
        <label class="col-form-label" for="txtusername">Fullname</label>
        <input
          class="form-control"
          type="text"
          minlength="4"
          v-model="user.fullname_user"
          required
          placeholder="Fullname"
        />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="txtusername">Email</label>
        <input
          class="form-control"
          type="email"
          minlength="4"
          v-model="user.email_user"
          required
          placeholder="Email"
        />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="mobile">Mobile No.</label>
        <input
          class="form-control"
          type="tel"
          required
          v-model="user.mobile_user"
          minlength="11"
          maxlength="11"
          placeholder="Mobile No."
        />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="txtBirth">Birth Date</label>
        <input
          class="form-control ui-datepicker-current"
          type="date"
          required
          minlength="8"
          v-model="user.birth_user"
          placeholder="Birth"
        />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="txtPassword">Password</label>
        <input
          class="form-control"
          type="password"
          id="pword"
          required
          minlength="8"
          v-model="user.pword_user"
          name="pword_user"
          placeholder="Password"
        />
      </div>
      <div class="form-group">
        <label class="col-form-label" for="txtPassword">Confirm Password</label>
        <input
          class="form-control"
          type="password"
          id="pwordconfirm"
          required
          minlength="8"
          name="pwordconfirm"
          v-model="user.pwordconfirm"
          placeholder="Confirm"
        />
      </div>
      <div class="form-group" id="ajaxmsg"></div>
      <div style="margin-top: 10px; margin-bottom: 10px" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="agreed"
          name="agreed"
          required
          v-model="agreed"
          @change="handleCheckboxChange"
          style="width: 20px; height: 20px"
        />
        <label
          class="form-check-label"
          style="margin-left: 5px; margin-top: 5px"
          for="agreed"
        >
          I agree to the
          <a href="#" @click.prevent="handleTermsClick"> terms and conditions</a>
        </label>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-danger w-100">Proceed</button>
      </div>
      <router-link :to="{ name: 'login' }" type="button" class="btn btn-secondary w-100">
        Cancel
      </router-link>
    </form>
    <div class="modal" tabindex="-1" role="dialog" :class="{ show: showModal }">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Terms and Conditions</h5>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto">
            <div>
              <h1><strong>Terms and Conditions</strong></h1>

              <p>
                Welcome to our Palompon Destinations! If you continue to browse and use
                this website, you are agreeing to comply with and be bound by the
                following terms and conditions of use, which together with our privacy
                policy govern our relationship with you in relation to this website.
                <b
                  >If you disagree with any part of these terms and conditions, please do
                  not use our website.</b
                >
              </p>
              <ul>
                <li>
                  The term "us" or "we" refers to the owner of the website. The term "you"
                  refers to the user or viewer of our website.
                </li>
              </ul>
              <h3><strong>Privacy Policy</strong></h3>
              <p>
                We take your privacy seriously and we are committed to protecting your
                personal information. Our privacy policy governs the collection, use and
                storage of your personal information. By using our website, you agree to
                our privacy policy.
              </p>
              <p><strong>1. Cookies</strong></p>
              <p>
                We may use cookies to enhance your browsing experience on our website. By
                using our website, you consent to the use of cookies in accordance with
                our privacy policy.
              </p>
              <p><strong>2. Location Data</strong></p>
              <p>
                We may collect and use your location data to provide better services and
                to help our technicians locate you more easily. We may also use this data
                to visualize areas that are affected by outages or other related concerns.
                We will only use this data for service purposes and will not share it with
                any third party without your explicit consent.
              </p>
              <p><strong>3.User Login Credentials</strong></p>
              <p>
                We use user login credentials to authenticate and authorize users to
                access certain parts of our website. By registering on our website, you
                agree to provide accurate and complete information and to keep your login
                credentials secure.
              </p>
              <p><strong>4. Intellectual Property</strong></p>
              <p>
                All intellectual property rights in our website, including without
                limitation any copyright, trademarks, and logos are owned by us or our
                licensors. You may not use any of our intellectual property without our
                prior written consent.
              </p>
              <p><strong>5. Liability</strong></p>
              <p>
                We make no representations or warranties of any kind, express or implied,
                about the completeness, accuracy, reliability, suitability or availability
                with respect to the website or the information, products, services, or
                related graphics contained on the website for any purpose. Any reliance
                you place on such information is therefore strictly at your own risk.
              </p>
              <p>
                In no event will we be liable for any loss or damage including without
                limitation, indirect or consequential loss or damage, or any loss or
                damage whatsoever arising from loss of data or profits arising out of, or
                in connection with, the use of our website.
              </p>
              <p><strong>6. Third-Party Links</strong></p>
              <p>
                Our website may contain links to third-party websites that are not under
                our control. We have no control over the nature, content, and availability
                of those sites. The inclusion of any links does not necessarily imply a
                recommendation or endorse the views expressed within them.
              </p>
              <p><strong>7. Modifications</strong></p>
              <p>
                We reserve the right to modify these terms and conditions at any time
                without prior notice. By using our website, you agree to be bound by the
                current version of these terms and conditions.
              </p>
              <p><strong>8. Governing Law</strong></p>
              <p>
                These terms and conditions shall be governed by and construed in
                accordance with the Philippine laws. Any dispute arising under These terms
                and conditions shall be governed by and construed in accordance with the
                Philippine laws. Any dispute arising under these terms and conditions
                shall be subject to the exclusive jurisdiction of the courts Philippines.
              </p>
              <p>
                Thank you for reading our terms and conditions. If you have any questions
                or concerns, please contact us at <b>palompondestinations@gmail.com</b>.
              </p>
              <br /><br />
              <h2>Privacy Statement</h2>
              <p>
                We at Exodous are committed to protecting your privacy and personal data.
                This Privacy Statement explains how we collect, use, and protect the
                personal information that you provide to us through our website and
                services.
              </p>
              <h3>1. What Personal Information We Collect</h3>
              <p>
                We collect personal information that you provide to us when you use our
                website and services. This includes your name, email address, phone
                number, and other contact information, as well as any other information
                that you provide to us.
              </p>
              <h3>2. How We Use Your Personal Information</h3>
              <p>
                We use your personal information to provide you with our services, to
                communicate with you about your account and our services, and to improve
                and customize our services. We may also use your personal information for
                other purposes, such as to conduct research and analysis, and to comply
                with legal and regulatory requirements.
              </p>
              <h3>3. How We Protect Your Personal Information</h3>
              <p>
                We take the security of your personal information seriously, and we use a
                variety of security technologies and procedures to help protect your
                personal information from unauthorized access, use, or disclosure.
                However, no method of transmission over the Internet or electronic storage
                is 100% secure, and we cannot guarantee the absolute security of your
                personal information.
              </p>
              <h3>4. Sharing Your Personal Information</h3>
              <p>
                We may share your personal information with third-party service providers
                who perform services on our behalf, such as payment processing and email
                marketing. We may also share your personal information with government
                authorities or other third parties as required by law or to protect our
                legal rights.
              </p>
              <h3>5. Your Rights</h3>
              <p>
                You have the right to access, correct, or delete your personal information
                at any time. You may also have the right to object to our use of your
                personal information, or to restrict the processing of your personal
                information. To exercise these rights, please contact us using the contact
                information provided below.
              </p>
              <h3>6. Changes to this Privacy Statement</h3>
              <p>
                We may update this Privacy Statement from time to time to reflect changes
                in our practices and services. We will notify you of any material changes
                by posting the new Privacy Statement on our website. Your continued use of
                our website and services after the posting of the updated Privacy
                Statement will constitute your acceptance of the changes.
              </p>
              <h3>7. Contact Us</h3>
              <p>
                If you have any questions or concerns about our Privacy Statement, or if
                you wish to exercise your rights under this Privacy Statement, please
                contact us at exodoustechsolutions@gmail.com.
              </p>
              <div>
                <button
                  class="btn btn-primary w-100"
                  type="button"
                  @click.prevent="handleAgree"
                >
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center min-vh-100">
    <div class="text-center">
      <i class="bi bi-envelope-check display-1 text-success"></i>
      <h1 class="mt-3">Registration Submitted</h1>
      <p class="lead">Please check your email to activate your account.</p>
      <router-link :to="{ name: 'login' }" class="btn btn-primary mt-3"
        >Go To Login Page</router-link
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import toastService from "@/services/toastService";
import spinnerService from "@/services/spinnerService";
import userService from "@/services/userService";
export default {
  setup() {
    const user = ref({
      fullname_user: "",
      email_user: "",
      mobile_user: "",
      pword_user: "",
      birth_user: null,
      pwordconfirm: "",
    });
    const agreed = ref(false);
    const hasAgreed = ref(false);
    const showModal = ref(false);
    const showConfirmation = ref(false);

    const handleSubmit = async () => {
      if (!hasAgreed.value) {
        toastService.error(
          "Please read our terms and conditions first in order to proceed."
        );
        return;
      }
      if (!/^09\d{9}$/.test(user.value.mobile_user)) {
        toastService.error("Mobile number must be 11 digits and start with 09");
        return;
      }
      if (user.value.pword_user !== user.value.pwordconfirm) {
        toastService.error("Passwords do not match");
        return;
      }
      if (user.value.pword_user.length < 8) {
        toastService.error("Password must be at least 8 characters long");
        return;
      }

      try {
        spinnerService.show();
        const response = await userService.signup(user.value);
        if (response.data.success) {
          showConfirmation.value = true;
        } else {
          toastService.error(response.data.message);
        }
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    const handleTermsClick = async () => {
      showModal.value = true;
    };
    const handleAgree = () => {
      hasAgreed.value = true;
      showModal.value = false;
      agreed.value = true;
    };
    const handleCheckboxChange = () => {
      if (agreed.value) {
        if (!hasAgreed.value) {
          showModal.value = true;
        }
      }
    };
    return {
      user,
      handleSubmit,
      showModal,
      handleTermsClick,
      handleAgree,
      agreed,
      handleCheckboxChange,
      showConfirmation,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
</style>
