<template><GuestsReportTable /></template>
<script>
import GuestsReportTable from "@/components/GuestsReportTable.vue";
export default {
  components: {
    GuestsReportTable,
  },
  setup() {},
};
</script>
