<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-group">
      <label>User</label>
      <input class="form-control" v-model="selectedUser.fullname_user" />
    </div>
    <div class="form-group">
      <label>Roles</label>
      <div v-for="role in roles" :key="role" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :id="role"
          :value="role"
          v-model="selectedRoles"
          :disabled="role === 'client'"
        />
        <label class="form-check-label" :for="role">{{ role }}</label>
      </div>
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-danger w-100">Save Changes</button>
    </div>
  </form>
</template>

<script>
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import userService from "@/services/userService";
import { ref, watch } from "vue";
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["user-updated"],
  setup(props, { emit }) {
    const roles = ref(["client", "admin", "teller"]);
    const selectedUser = ref({ ...props.user });
    const selectedRoles = ref([]);

    // Initialize selectedRoles based on user roles
    watch(
      () => props.user,
      (newUser) => {
        selectedUser.value = { ...newUser };
        selectedRoles.value = newUser.role_user.split(",").includes("client")
          ? newUser.role_user.split(",")
          : [...newUser.role_user.split(","), "client"];
      },
      { immediate: true }
    );

    const handleSubmit = async () => {
      // Ensure 'client' role is always included
      if (!selectedRoles.value.includes("client")) {
        selectedRoles.value.push("client");
      }
      let newroles = selectedRoles.value.join(",");
      if (newroles === selectedUser.value.role_user) {
        toastService.error("No changes needed to save.");
        return;
      }
      selectedUser.value.role_user = newroles;
      try {
        const response = await userService.updateUserRoles(selectedUser.value);
        if (response.data.success) {
          toastService.success("User updated successfully");
          emit("user-updated", selectedUser.value);
        } else {
          toastService.error(response.data.message);
        }
      } catch (err) {
        toastService.error("An error occured while processing the request");
      } finally {
        spinnerService.hide();
      }
    };

    return {
      selectedUser,
      handleSubmit,
      roles,
      selectedRoles,
    };
  },
};
</script>
