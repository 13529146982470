<template>
  <div class="d-flex align-items-center justify-content-center min-vh-100">
    <form
      class="p-4 bg-light rounded shadow-sm"
      style="min-width: 350px; max-width: 100%"
      @submit.prevent="handleSubmit"
    >
      <div class="text-center mb-4" style="margin-top: -105px">
        <router-link to="/">
          <img src="/favicon.ico" class="rounded-circle" height="150" width="150" />
        </router-link>
      </div>
      <h2 class="text-center mb-4">Login</h2>

      <!-- Username field -->
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input
          type="text"
          id="username"
          v-model="username"
          class="form-control"
          required
        />
      </div>

      <!-- Password field -->
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input
          type="password"
          id="password"
          v-model="password"
          class="form-control"
          required
        />
      </div>

      <div class="col-12 mb-2 px-0">
        <div v-if="errmsg" class="alert alert-danger mx-0">{{ errmsg }}</div>
      </div>

      <div class="d-flex justify-content-between mb-3">
        <router-link class="text-secondary" :to="{ name: 'forgotpassword' }"
          >Forgot Password?</router-link
        >
        <router-link class="text-secondary" :to="{ name: 'signup' }"
          >Create an Account</router-link
        >
      </div>

      <!-- Submit button -->
      <button type="submit" class="btn btn-danger w-100 mb-2">Login</button>
      <button
        type="button"
        @click.prevent="handleCancelClick"
        class="btn btn-secondary w-100"
      >
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

export default {
  name: "LoginPage",
  setup() {
    const username = ref("");
    const password = ref("");
    const store = useStore();
    const errmsg = ref("");
    const returnURL = router.currentRoute.value.query.return_url || "/";

    const handleSubmit = async () => {
      try {
        errmsg.value = "";
        await store.dispatch("auth/login", {
          username: username.value,
          password: password.value,
        });
        router.replace(returnURL);
      } catch (error) {
        if (error.error) {
          errmsg.value = error.error.message;
        } else {
          errmsg.value = "Server is unreachable.";
        }
      }
    };

    const handleCancelClick = () => {
      router.replace("/");
    };

    return {
      username,
      password,
      handleSubmit,
      handleCancelClick,
      errmsg,
    };
  },
};
</script>

<style scoped>
/* Ensure the form has a minimum and maximum width for responsiveness */
@media (max-width: 576px) {
  form {
    width: 100%;
  }
}
</style>
