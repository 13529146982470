<template>
  <form @submit.prevent="handleFormSubmit">
    <div class="row p-4 bg-light rounded-4">
      <div class="form-group mb-2">
        <label for="tourtype" class="text-dark">Tour Type:</label>
        <select v-model="loadedBooking.tourtype_booking" class="form-select">
          <option v-for="item in tourtypes" :key="item.id" :value="item.title">
            {{ item.title }}
          </option>
        </select>
      </div>
      <div class="form-group mb-2">
        <label for="tourdate" class="text-dark">Tour Date:</label>
        <input
          type="date"
          class="form-control"
          v-model="loadedBooking.tourdate_booking"
        />
      </div>
      <div class="form-group mb-2">
        <label for="tourdate" class="text-dark">Total Guests:</label>
        <input
          type="text"
          class="form-control text-end"
          readonly
          :value="loadedBooking.qty"
        />
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-danger w-100">Save Changes</button>
      </div>
    </div>
  </form>
</template>

<script>
import { computed, reactive, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import apiService from "@/services/apiService";
import { formatDateString } from "@/utils/formatter";
export default {
  props: {
    booking: {
      type: Object,
      required: true,
      default() {
        return {
          qty: 1,
          tourdate_booking: "",
          tourtype_booking: "Day Tour",
        };
      },
    },
  },
  emits: ["booking-updated"],
  setup(props, { emit }) {
    const store = useStore();
    const loadedBooking = ref(computed(() => props.booking));
    // const currentUser = computed(() => store.getters["auth/currentUser"]);
    const state = reactive({
      currentInquiry: {
        type: "Day Tour",
        date: "",
        tourTime: null,
        totalGuests: 1,
      },
      tourTimes: [
        {
          name: "Day Tour",
          values: ["06:00 - 09:00 AM"],
        },
        {
          name: "Overnight",
          values: ["12:00 - 03:00 PM"],
        },
      ],
      tourtypes: [
        {
          id: 1,
          title: "Day Tour",
        },
        {
          id: 2,
          title: "Overnight",
        },
      ],
    });
    async function handleFormSubmit() {
      store.dispatch("showSpinner", true);
      try {
        let par = {
          syspk_booking: loadedBooking.value.syspk_booking,
          tourtype_booking: loadedBooking.value.tourtype_booking,
          tourdate_booking: loadedBooking.value.tourdate_booking,
          amount_booking: loadedBooking.value.amount_booking,
        };
        const data = await apiService.post(
          `v1/b/modify/${loadedBooking.value.syspk_booking}`,
          par,
          true
        );
        if (data.data.success) {
          par.amount_booking = Number(data.data.message);
          emit("booking-updated", par);
        } else {
          store.dispatch("errorToast", data.data.message);
        }
      } catch (error) {
        console.error(error);
        store.dispatch("errorToast", "An error occured while processing the request");
      } finally {
        store.dispatch("hideSpinner", false);
      }
    }
    onMounted(() => {
      state.currentInquiry.tourTime = state.tourTimes[0].values[0];
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      state.currentInquiry.date = formatDateString(currentDate);
    });
    const filteredTourTimes = computed(() => {
      const selectedType = state.currentInquiry.type;
      const selectedTourTime = state.tourTimes.find((item) => item.name === selectedType);
      return selectedTourTime ? selectedTourTime.values : [];
    });
    watch(filteredTourTimes, (newValue, oldValue) => {
      console.log(oldValue);
      state.currentInquiry.tourTime = newValue[0];
    });
    return {
      currentInquiry: state.currentInquiry,
      tourtypes: state.tourtypes,
      filteredTourTimes,
      handleFormSubmit,
      loadedBooking,
    };
  },
};
</script>
