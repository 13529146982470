<template>
    <TheEmptyHeader/>
  <section class="section mt-4">
    <div class="container">
      <Info :id="currentId" />
    </div>
  </section>
</template>

<script>
import Info from "@/pages/Bookings/Info.vue";
import TheEmptyHeader from "@/components/TheEmptyHeader.vue";
import { onMounted, ref } from "vue";
export default {
  components: {
    Info,
    TheEmptyHeader
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const currentId = ref(props.id);

    onMounted(() => {
      currentId.value = props.id;
    });
    return {
      currentId,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
</style>
