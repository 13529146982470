<template>
  <div>
    <input v-model="searchQuery" placeholder="Search..." class="form-control mb-3" />
    <table class="table table-bordered">
      <thead>
        <tr>
          <th v-for="(header, key) in headers" :key="key">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in paginatedItems" :key="index">
          <td v-for="(header, key) in headers" :key="key">{{ item[key] }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination" v-if="totalPages > 0">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Object,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 25,
    },
  },
  setup(props) {
    const searchQuery = ref("");
    const currentPage = ref(1);

    const filteredItems = computed(() => {
      if (!searchQuery.value) return props.items;
      return props.items.filter((item) =>
        Object.keys(props.headers).some((key) =>
          String(item[key]).toLowerCase().includes(searchQuery.value.toLowerCase())
        )
      );
    });

    const totalPages = computed(() =>
      Math.ceil(filteredItems.value.length / props.itemsPerPage)
    );

    const paginatedItems = computed(() => {
      const start = (currentPage.value - 1) * props.itemsPerPage;
      const end = start + props.itemsPerPage;
      return filteredItems.value.slice(start, end);
    });

    const nextPage = () => {
      if (currentPage.value < totalPages.value) currentPage.value++;
    };

    const prevPage = () => {
      if (currentPage.value > 1) currentPage.value--;
    };

    watch(totalPages, (newValue) => {
      if (newValue === 0) currentPage.value = 0;
      else currentPage.value = 1;
    });

    return {
      searchQuery,
      currentPage,
      paginatedItems,
      totalPages,
      nextPage,
      prevPage,
    };
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pagination button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
