<template>
  <form
    @submit.prevent="handleSubmit"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <div class="card-title text-center">
      <h3 class="text-info mt-3">Please provide the required information to proceed.</h3>
    </div>
    <div style="overflow-y: auto; flex: 1">
      <div class="container">
        <div v-for="(guest, index) in guests" :key="index" class="card mb-2 px-3">
          <div class="row">
            <div class="text-center fw-bold mt-2">
              Guest # {{ index + 1 }} Information
            </div>
            <div class="form-group mb-2">
              <label for="fullname">Guest Name</label>
              <input
                required
                type="text"
                class="form-control"
                id="fullname"
                v-model.trim="guest.fullname_guest"
              />
            </div>
            <div class="form-group mb-2 col-6">
              <label for="gender">Gender</label>
              <select required class="form-select" v-model="guest.gender_guest">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div class="form-group mb-2 col-6">
              <label for="birth">Birth</label>
              <input
                required
                class="form-control"
                type="date"
                v-model="guest.birthdate_guest"
              />
            </div>

            <div class="form-group mb-2 col-6">
              <label for="type">Type</label>
              <select required class="form-select" v-model="guest.type_guest">
                <option value="International">International</option>
                <option value="Tourist">Tourist</option>
                <option value="Gov't Employee">Gov't Employee</option>
                <option value="Elem. Pupil">Elem. Pupil</option>
                <option value="College Student">College Student</option>
                <option value="HS Student">HS Student</option>
                <option value="Senior Cit.">Senior Cit.</option>
              </select>
            </div>

            <div class="form-group mb-2 col-6">
              <label for="residence">Residence</label>
              <select required class="form-select" v-model="guest.residence_guest">
                <option value="Palomponganon">Palomponganon</option>
                <option value="Non-Palomponganon">Non-Palomponganon</option>
              </select>
            </div>
            <div class="form-group mb-2">
              <label for="cost">Address</label>
              <input
                type="text"
                class="form-control text-end"
                v-model="guest.mobileNo_guest"
              />
            </div>
            <div class="form-group mb-2 col-lg-9">
              <label for="cost">Mobile No</label>
              <input
                type="text"
                class="form-control text-end"
                v-model="guest.address_guest"
              />
            </div>
            <div class="form-group mb-2 col-lg-3">
              <label for="cost">Fee</label>
              <input
                type="text"
                class="form-control text-end"
                id="cost"
                :value="formatValue(guest.price_guest)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mb-2">
      <button type="submit" class="btn btn-danger w-100">Save Changes</button>
    </div>
  </form>
</template>

<script>
import { watch, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { formatNumber, formatDateString } from "@/utils/formatter";
import { apiUrl } from "@/utils/config";
import axios from "axios";
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["newguests-added"],
  setup(props, { emit }) {
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const guests = ref([]);
    const store = useStore();
    const costings = computed(() => store.state.costings);
    const currentConfig = ref(computed(() => props.config));
    const formatDate = (date) => {
      return formatDateString(date);
    };
    const formatValue = (number) => {
      return formatNumber(number);
    };
    onMounted(() => {
      let gsts = [];
      for (let i = 0; i < currentConfig.value.totalGuests; i++) {
        gsts.push({
          fullname_guest: "",
          type_guest: "Tourist",
          residence_guest: "Non-Palomponganon",
        });
      }
      guests.value = gsts;
    });

    const handleSubmit = async () => {
      try {
        store.dispatch("showSpinner");
        const response = await axios.post(
          `${apiUrl}v1/b/addgueststobooking`,
          {
            bookingId: currentConfig.value.bookingId,
            tourType: currentConfig.value.type,
            guests: guests.value,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser.value.accessToken}`,
              "Content-Type": "application/json", // Optional: set the content type if needed
            },
          }
        );
        if (response.data.success) {
          store.dispatch("successToast", "New guests added successfully!");
          emit("newguests-added", response.data.guests);
        } else {
          store.dispatch("errorToast", response.data.message);
        }
      } catch (err) {
        console.log(err);
        store.dispatch("errorToast", "An error occured while processing the request.");
      } finally {
        store.dispatch("hideSpinner");
      }
    };

    watch(
      guests,
      (newGuests) => {
        newGuests.forEach((newGuest) => {
          let cost = costings.value.find(
            (x) =>
              x.tourpack_costing === currentConfig.value.type &&
              x.guesttype_costing === newGuest.type_guest &&
              x.residence_costing === newGuest.residence_guest
          );
          if (cost && cost.price_costing !== newGuest.price_guest) {
            newGuest.price_guest = cost.price_costing;
          }
        });
      },
      { deep: true }
    );

    return {
      handleSubmit,
      formatValue,
      formatDate,
      guests,
    };
  },
};
</script>
