<template>
  <ConfirmDialog />
  <TheEmptyHeader />

  <section id="hero" class="hero section">
    <div class="container bg-light rounded-2" style="height: 90vh; margin-bottom: 50px">
      <form
        v-if="!isBookingSuccessful"
        @submit.prevent="handleSubmitBooking"
        style="height: 100%; display: flex; flex-direction: column"
      >
        <div class="card-title text-center">
          <h3 class="text-info mt-3">
            Please provide the required information to proceed.
          </h3>
        </div>
        <div class="mt-3 text-dark">
          <h4 class="d-flex justify-content-between text-dark">
            <span>{{ currentConfig.type }}</span>
            <span class="badge bg-warning">₱ {{ formatValue(totalPrice) }}</span>
          </h4>
          <label style="text-align: center">
            <strong>{{ currentConfig.date }}</strong>
            <span v-if="currentConfig.type === 'Day Tour'" class="text-danger ms-2"
              >Day tours actual departure time can be anytime between 06:00 AM - 09:00
              AM.</span
            >
            <span v-else class="text-danger ms-2"
              >Overnight tours actual departure time can be anytime between 12:00 NN -
              03:00 PM.</span
            >
          </label>
        </div>
        <div class="text-end">
          <button type="button" title="Add more guests" class="btn btn-success my-2">
            <i class="bi bi-plus"> </i>
          </button>
        </div>
        <div style="overflow-y: auto; flex: 1">
          <div class="container">
            <div v-for="(guest, index) in guests" :key="index" class="card mb-2 px-3">
              <div class="row">
                <div class="text-center fw-bold mt-2">
                  Guest # {{ index + 1 }} Information
                </div>
                <div class="form-group mb-2">
                  <label for="fullname">Guest Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="fullname"
                    v-model.trim="guest.fullname_guest"
                  />
                </div>
                <div class="form-group mb-2 col-6">
                  <label for="gender">Gender</label>
                  <select required class="form-select" v-model="guest.gender_guest">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="form-group mb-2 col-6">
                  <label for="birth">Age</label>
                  <input
                    required
                    class="form-control"
                    type="number"
                    min="1"
                    v-model="guest.age_guest"
                  />
                </div>

                <div class="form-group mb-2 col-6">
                  <label for="type">Type</label>
                  <select required class="form-select" v-model="guest.type_guest">
                    <option value="International">International</option>
                    <option value="Tourist">Tourist</option>
                    <option value="Gov't Employee">Gov't Employee</option>
                    <option value="Elem. Pupil">Elem. Pupil</option>
                    <option value="College Student">College Student</option>
                    <option value="HS Student">HS Student</option>
                    <option value="Senior Cit.">Senior Cit.</option>
                  </select>
                </div>

                <div class="form-group mb-2 col-6">
                  <label for="residence">Residence</label>
                  <select required class="form-select" v-model="guest.residence_guest">
                    <option value="Palomponganon">Palomponganon</option>
                    <option value="Non-Palomponganon">Non-Palomponganon</option>
                  </select>
                </div>
                <div class="form-group mb-2">
                  <label for="cost">Address</label>
                  <input type="text" class="form-control" v-model="guest.address_guest" />
                </div>
                <div class="form-group mb-2 col-lg-9">
                  <label for="cost">Mobile No</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="guest.mobileNo_guest"
                  />
                </div>
                <div class="form-group mb-2 col-lg-3">
                  <label for="cost">Fee</label>
                  <input
                    type="text"
                    class="form-control text-end"
                    id="cost"
                    :value="formatValue(guest.price_guest)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mb-2">
          <button type="submit" class="btn btn-danger w-100">Save Changes</button>
        </div>
      </form>
      <div v-else>
        <div class="text-center my-5">
          <i class="bi bi-check-circle-fill" style="font-size: 5rem; color: green"></i>
          <h1 class="text-success">Congratulations!</h1>
          <p class="text-dark">
            Your booking was successful and is now waiting to be approved. You will be
            notified via email once it is approved.
          </p>
          <router-link :to="{ name: 'my-bookings' }" class="btn btn-primary mt-4"
            ><i class="bi bi-book" /> Go to My Bookings</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getInitBookingConfig, apiUrl, clearInitBookingConfig } from "@/utils/config";
import { onMounted, ref, watchEffect, computed } from "vue";
import { formatNumber } from "@/utils/formatter";
import { useStore } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import TheEmptyHeader from "@/components/TheEmptyHeader.vue";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  components: {
    ConfirmDialog,
    TheEmptyHeader,
  },
  setup() {
    const currentConfig = getInitBookingConfig();
    const guests = ref([]);
    const isBookingSuccessful = ref(false);
    const newBookingId = ref("");
    const store = useStore();
    const toast = useToast();
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const costings = ref([]);

    const formatValue = (number) => {
      return formatNumber(number);
    };

    // Function to fetch costings data
    const loadCostings = async () => {
      try {
        const response = await axios.get(`${apiUrl}v1/b/getcostings`);
        costings.value = response.data;
      } catch (error) {
        console.error("Error loading costings:", error);
      }
    };

    onMounted(async () => {
      await loadCostings();
      let gsts = [];
      for (let i = 0; i < currentConfig.totalGuests; i++) {
        gsts.push({
          fullname_guest: "",
          residence_guest: "Non-Palomponganon",
          type_guest: "Tourist",
          price_guest: 0, // Initialize price_guest to 0
          birthdate_guest: null,
        });
      }
      guests.value = gsts;
    });

    const handleSubmitBooking = async () => {
      let reqData = {
        tourType: currentConfig.type,
        tourTime: currentConfig.tourTime,
        tourDate: currentConfig.date,
        guests: guests.value,
      };
      store.dispatch("showSpinner", true);
      try {
        const data = await axios.post(`${apiUrl}v1/b/addbooking`, reqData, {
          headers: {
            Authorization: `Bearer ${currentUser.value.accessToken}`,
            "Content-Type": "application/json",
          },
        });
        console.log(data);
        if (data.data.success) {
          toast.success("success");
          clearInitBookingConfig();
          newBookingId.value = data.data.sysref;
          isBookingSuccessful.value = true;
        } else {
          toast.error(data.data.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while processing the request");
      } finally {
        store.dispatch("hideSpinner", false);
      }
    };

    const totalPrice = computed(() => {
      if (!costings.value.length) return 0;
      return guests.value.reduce(
        (acc, guest) => acc + parseFloat(guest.price_guest || 0),
        0
      );
    });

    watchEffect(() => {
      if (costings.value.length && guests.value.length) {
        guests.value.forEach((newGuest) => {
          let cost = costings.value.find(
            (x) =>
              x.tourpack_costing === currentConfig.type &&
              x.guesttype_costing === newGuest.type_guest &&
              x.residence_costing === newGuest.residence_guest
          );
          if (cost && cost.price_costing !== newGuest.price_guest) {
            newGuest.price_guest = cost.price_costing;
          }
        });
      }
    });

    return {
      currentConfig,
      guests,
      formatValue,
      totalPrice,
      handleSubmitBooking,
      isBookingSuccessful,
      newBookingId,
    };
  },
};
</script>
