<template>
  <div class="printable-content">
    <div class="d-none d-print-block">
      <!-- Display two copies of the image, side by side at the top -->
      <div class="images-container">
        <img :src="imageSrc" class="logo left-image" />
        <div class="cutting-guide"></div>
        <!-- Vertical dashed line -->
        <img :src="imageSrc" class="logo right-image" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import bookingService from "@/services/bookingService";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { expose }) {
    const imageSrc = ref("");
    const selectedId = computed(() => props.id);

    const getImage = async () => {
      if (!selectedId.value || selectedId.value === 0) return;
      try {
        spinnerService.show();
        const response = await bookingService.getTripTicket(selectedId.value);
        imageSrc.value = response.data.ticketImg;
        setTimeout(() => {
          window.print();
        }, 200);
      } catch (err) {
        toastService.error("An error occurred while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    // Expose getImage method
    expose({ getImage });

    return {
      imageSrc,
    };
  },
};
</script>

<style scoped>
/* Printable content styling */
.printable-content {
  display: none;
}

@media print {
  .printable-content {
    display: block;
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    margin: 0;
    padding: 0;
  }

  .d-print-block {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align content to the top */
    width: 100%; /* Occupy full width of A4 */
    height: 100%; /* Occupy full height of A4 */
    position: absolute;
    top: 0;
    left: 0;
  }

  .images-container {
    display: flex;
    position: relative;
    width: 100%; /* Ensure images occupy full width */
    justify-content: space-between; /* Space evenly between the two images */
    align-items: flex-start; /* Align images to the top */
  }

  .logo {
    max-width: 48%; /* Ensure images fit within half of A4 width with small gap */
    height: auto; /* Maintain aspect ratio */
    display: block;
  }

  .cutting-guide {
    border-left: 1px dashed #000; /* Vertical dashed line */
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Hide other elements during print */
  body * {
    visibility: hidden;
  }

  .printable-content,
  .printable-content * {
    visibility: visible;
  }

  .printable-content {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
