function formatNumber(number) {
    // Convert number to string and round to two decimal places
    let formattedNumber = parseFloat(number).toFixed(2);

    // Add commas as thousand separators
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedNumber;
}

function formatDateString(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function formatPhDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function formatPhDateString(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = String(hours % 12 || 12).padStart(2, '0'); // Converts 0 to 12 for 12-hour format
  
    return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  }

function numberToWords(num) {
    if (!num)
        return '';
    const belowTwenty = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands = ["", "Thousand", "Million", "Billion"];

    function helper(n) {
        if (n === 0) return "";
        else if (n < 20) return belowTwenty[n] + " ";
        else if (n < 100) return tens[Math.floor(n / 10)] + " " + helper(n % 10);
        else return belowTwenty[Math.floor(n / 100)] + " Hundred " + helper(n % 100);
    }

    function convert(num) {
        if (num === 0) return "Zero";
        let i = 0;
        let words = "";

        while (num > 0) {
            if (num % 1000 !== 0) {
                words = helper(num % 1000) + thousands[i] + " " + words;
            }
            num = Math.floor(num / 1000);
            i++;
        }

        return words.trim();
    }

    const [pesos, centavos] = num.toFixed(2).split(".");
    const pesosInWords = convert(Number(pesos));
    const centavosInWords = centavos.padStart(2, '0');
    return `${pesosInWords} Pesos and ${centavosInWords}/100 Only`;
}

function calculateAge(birthdate) {
    if (!birthdate) return '';
  
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age.toString();
  }

  function getYear(date) {
    return new Date(date).getFullYear();
  }
  
  // Get the full month name from a Date object
  function getFullMonth(date) {
    if(!date || date===null)
        return "";
    const options = { month: 'long' };
    return new Date(date).toLocaleDateString('en-US', options);
  }

export { formatNumber, formatDateString,formatPhDateString, numberToWords,calculateAge,getYear,getFullMonth,formatPhDate };