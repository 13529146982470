<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-group mb-2">
      <label>Enter Qty:</label>
      <input type="number" v-model="selectedQty" class="form-control text-end mt-2" />
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary w-100">Check Availability</button>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { apiUrl } from "@/utils/config";
export default {
  props: {
    currentQty: {
      type: Number,
      required: true,
    },
    tourDate: {
      type: String,
      required: true,
    },
    tourType: {
      type: String,
      required: true,
    },
  },
  emits: ["slots-available"],
  setup(props, { emit }) {
    const selectedQty = ref(1);
    const store = useStore();
    const handleSubmit = async () => {
      try {
        store.dispatch("showSpinner");
        const response = await axios.get(`${apiUrl}v1/b/checkavailability`, {
          params: {
            start: props.tourDate,
            total: selectedQty.value,
            tourType: props.tourType,
          },
        });
        if (response.data.success) {
          emit("slots-available", selectedQty.value);
        } else {
          store.dispatch('errorToast',response.data.message);
        }
      } catch {
        store.dispatch('errorToast','An error occured while fetching data.')
      } finally {
        store.dispatch("hideSpinner");
      }
    };

    return {
      handleSubmit,
      selectedQty,
    };
  },
};
</script>
