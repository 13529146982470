import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/HomePage.vue'; // Adjust the path as needed
import InitializeBooking from '@/pages/InitializeBooking.vue'
import Login from '@/pages/Login.vue'
import Admin from '@/pages/Admin.vue'
import { isAuthenticated, getUserRoles } from '@/utils/auth';
import NotFound from '@/pages/NotFound.vue';
import PendingBookings from '@/pages/Bookings/Pending.vue';
import ApprovedBookings from '@/pages/Bookings/Approved.vue';
import RejectedBookings from '@/pages/Bookings/Rejected.vue';
import RevokedBookings from '@/pages/Bookings/Revoked.vue';
import BookingInfo from '@/pages/Bookings/Info.vue';
import MyBookings from '@/pages/Bookings/MyBookings.vue'
import MyBookingInfo from '@/pages/Bookings/MyBookingInfo.vue';
import GuestsPage from '@/pages/Guests/GuestsPage.vue'
import ManifestoPage from '@/pages/ManifestoPage.vue'
import ForgotPassword from "@/pages/ForgotPassowrd.vue";
import ManifestoDetails from '@/pages/ManifestoDetails.vue';
import { getInitBookingConfig, adminTellerRoles, superAdminRoles } from '@/utils/config';
import BoatsPage from '@/pages/Boats/BoatsPage.vue';
import GuestsReportPage from "@/pages/Guests/GuestsReportPage.vue";
import UsersPage from '@/pages/Users/UsersPage.vue';
import SignupPage from "@/pages/SignupPage.vue";
import ActivationPage from "@/pages/ActivationPage.vue";
import IslandSettings from "@/pages/IslandSettings.vue";
import SetNewPassword from  "@/pages/Users/SetNewPassword.vue";
import GuestQRPage from "@/pages/Guests/GuestQRPage.vue";
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {
            // Check if user is logged in
            if (isAuthenticated()) {
              // If logged in, redirect to home
              next('/');
            } else {
              // Proceed to the login page
              next();
            }
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignupPage,
        beforeEnter: (to, from, next) => {
            // Check if user is logged in
            if (isAuthenticated()) {
              // If logged in, redirect to home
              next('/');
            } else {
              // Proceed to the login page
              next();
            }
        }
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: ForgotPassword,
        beforeEnter: (to, from, next) => {
            // Check if user is logged in
            if (isAuthenticated()) {
              // If logged in, redirect to home
              next('/');
            } else {
              // Proceed to the login page
              next();
            }
        }
    },
    {
        path: '/booking-init',
        name: 'booking-init',
        component: InitializeBooking,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-bookings',
        name: 'my-bookings',
        component: MyBookings,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-booking-info/:id',
        name: 'my-booking-info',
        component: MyBookingInfo,
        props: route => ({ id: Number(route.params.id) }),
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/bookings/pending',
        name: 'pending',
        component: PendingBookings,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/bookings/approved',
        name: 'approved',
        component: ApprovedBookings,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/booking-info/:id',
        name: 'booking-info',
        component: BookingInfo,
        props: route => ({ id: Number(route.params.id) }),
        meta: {
            requiresParam: true, requiresAuth: true, roles: adminTellerRoles
        },
        beforeEnter: (to, from, next) => {
            if (to.params.id) {
                // Proceed to the route if id parameter is present
                next();
            } else {
                // Redirect to a 404 Not Found page or another route
                next({ name: 'NotFound' });
            }
        }
    },
    {
        path: '/guest/guestqr/:id',
        name: 'guestqr',
        component: GuestQRPage,
        props: route => ({ id: Number(route.params.id) }),
        meta: {
            requiresParam: true, requiresAuth: true, roles: adminTellerRoles
        },
        beforeEnter: (to, from, next) => {
            if (to.params.id) {
                // Proceed to the route if id parameter is present
                next();
            } else {
                // Redirect to a 404 Not Found page or another route
                next({ name: 'NotFound' });
            }
        }
    },
    {
        path: '/account/activate/:code',
        name: 'activate',
        component: ActivationPage,
        props: route => ({ code: route.params.code }),
        meta: {
            requiresParam: true
        },
        beforeEnter: (to, from, next) => {
            if (to.params.code) {
                // Proceed to the route if id parameter is present
                next();
            } else {
                // Redirect to a 404 Not Found page or another route
                next({ name: 'NotFound' });
            }
        }
    },
    {
        path: '/account/resetpassword/:code',
        name: 'resetpassword',
        component: SetNewPassword,
        props: route => ({ code: route.params.code }),
        meta: {
            requiresParam: true
        },
        beforeEnter: (to, from, next) => {
            if (to.params.code) {
                // Proceed to the route if id parameter is present
                next();
            } else {
                // Redirect to a 404 Not Found page or another route
                next({ name: 'NotFound' });
            }
        }
    },
    {
        path: '/admin/bookings/rejected',
        name: 'rejected',
        component: RejectedBookings,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/bookings/revoked',
        name: 'revoked',
        component: RevokedBookings,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/pendingbookings',
        name: 'pendingBookings',
        component: PendingBookings,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/guests/:status',
        name: 'guests',
        component: GuestsPage,
        props: true,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/guests/report',
        name: 'guests-report',
        component: GuestsReportPage,
        props: true,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/manifesto',
        name: 'manifesto',
        component: ManifestoPage,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/boats',
        name: 'boats',
        component: BoatsPage,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/boats-queue',
        name: 'boats-queue',
        component: BoatsPage,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },

    {
        path: '/admin/users',
        name: 'users',
        component: UsersPage,
        meta: { requiresAuth: true, roles: superAdminRoles }
    },
    {
        path: '/admin/island',
        name: 'island',
        component: IslandSettings,
        meta: { requiresAuth: true, roles: adminTellerRoles }
    },
    {
        path: '/admin/manifesto/:id',
        name: 'manifesto-details',
        component: ManifestoDetails,
        props: route => ({ id: Number(route.params.id) }),
        meta: {
            requiresParam: true, requiresAuth: true, roles: adminTellerRoles
        },
        beforeEnter: (to, from, next) => {
            if (to.params.id) {
                // Proceed to the route if id parameter is present
                next();
            } else {
                // Redirect to a 404 Not Found page or another route
                next({ name: 'NotFound' });
            }
        }
    },

    { path: '/:catchAll(.*)', component: NotFound, meta: { showHeader: false } }
    // Add more routes here
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash };
        }
        return savedPosition || { top: 0 };
    }
});

router.beforeEach((to, from, next) => {

    // Check if the route requires authentication and the user is authenticated
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // Redirect to login page with return URL as query parameter
        next({
            path: '/login',
            query: { return_url: to.fullPath }
        });
    } else {
        // Check role permissions if roles are specified in meta
        if (to.meta.roles && to.meta.roles.length > 0) {
            const userRoles = getUserRoles(); // Example function to get user roles
            const allowedRoles = to.meta.roles;

            // Check if any of the user's roles match the allowed roles
            const hasAccess = userRoles.some(role => allowedRoles.includes(role));

            if (!hasAccess) {
                // Redirect to unauthorized page or handle unauthorized access
                next('/unauthorized'); // Example redirect to unauthorized page
            } else {
                // Continue to the route
                if (to.path === '/booking-init' && !getInitBookingConfig()) {
                    next('/');
                }
                else {
                    next();
                }
            }
        } else {
            // No role check required, continue to the route
            
            next();
        }
    }

});


export default router;
