<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h3>BOATS</h3>
      <div>
        <button @click.prevent="handleAddBoat" class="btn btn-success">
          <i class="bi bi-plus"></i> Add New Boat
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Company/Owner</th>
            <th>Captain</th>
            <th>Capacity</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="boat in boats" :key="boat.syspk_boat">
            <td>{{ boat.name_boat }}</td>
            <td>{{ boat.company_boat }}</td>
            <td>{{ boat.captain_boat }}</td>
            <td class="text-center">{{ boat.maxpassenger_boat }}</td>
            <td>
              <div class="btn-group">
                <button @click="editBoat(boat)" class="btn btn-secondary btn-sm">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal" tabindex="-1" role="dialog" :class="{ show: showModal }">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Boat Information</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow-y: auto">
          <EditBoatForm
            v-if="modalAction === 'edit'"
            :boat="selectedBoat"
            @boat-updated="boatUpdated"
            @boat-added="boatAdded"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import EditBoatForm from "@/components/forms/EditBoatForm.vue";
import boatService from "@/services/boatService";
export default {
  components: {
    EditBoatForm,
  },
  setup() {
    const boats = ref({});
    const showModal = ref(false);
    const modalAction = ref("");
    const selectedBoat = ref({});
    const handleAddBoat = () => {
      resetModal();
      modalAction.value = "edit";
      selectedBoat.value = {
        syspk_boat: 0,
        name_boat: "",
        company_boat: "",
        captain_boat: "",
        companycontact_boat: "",
        captaincontact_boat: "",
        maxpassenger_boat: 20,
      };
      showModal.value = true;
    };
    const fetchData = async () => {
      boats.value = await boatService.get();
    };

    const resetModal = () => {
      showModal.value = false;
      modalAction.value = "";
    };

    const editBoat = (boat) => {
      resetModal();
      selectedBoat.value = { ...boat };
      modalAction.value = "edit";
      showModal.value = true;
    };

    const boatUpdated = (boat) => {
      let idx = boats.value.findIndex((x) => x.syspk_boat === boat.syspk_boat);
      if (idx > -1) {
        boats.value[idx] = boat;
      }
      resetModal();
    };

    const boatAdded = (boat) => {
      boats.value.push(boat);
      resetModal();
    };

    onMounted(async () => {
      await fetchData();
    });
    return {
      boats,
      showModal,
      editBoat,
      selectedBoat,
      resetModal,
      modalAction,
      boatUpdated,
      boatAdded,
      handleAddBoat,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
th {
  cursor: pointer;
}
</style>
