<template>
  <div class="row d-print-none">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5>
        <b
          >Booking
          <span class="text-info">#{{ loadedBookingInfo.booking.syspk_booking }}</span> -
        </b>
        <b>
          <span class="text-warning">{{ loadedBookingInfo.booking.status_booking }}</span>
        </b>
      </h5>
      <div class="btn-group">
        <button
          @click.prevent="handleAddGuest"
          title="Add guests"
          class="btn btn-success"
        >
          <i class="bi bi-plus" />
        </button>
        <button
          v-if="hasAdminTellerRoles"
          @click.prevent="printTripTicket"
          title="Print Trip Ticket"
          class="btn btn-success"
        >
          <i class="bi bi-printer" />
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" style="margin: 5px">
      <div>
        <div class="row">
          <div class="col-sm-auto">
            <label class="pull-left" for="bookedby">Booked By:</label>
          </div>
          <div class="col-sm-auto">
            <label class="text-info" style="font-weight: bold">{{
              loadedBookingInfo.booking.recMarker
            }}</label>
          </div>
          <div class="col-auto w-10">
            <label class="pull-left" for="bookedby">Mobile No:&nbsp;</label>
          </div>
          <div class="col-auto">
            <label class="text-info" style="font-weight: bold">{{
              loadedBookingInfo.booking.recordIndecies
            }}</label>
          </div>
          <div class="col-auto w-10">
            <label class="pull-left" for="bookedby">Date:&nbsp;</label>
          </div>
          <div class="col-auto">
            <label class="text-info" style="font-weight: bold">{{
              loadedBookingInfo.booking.tourdate_booking
            }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-auto w-10">
            <label class="pull-left" for="bookedby">Tour:&nbsp;</label>
          </div>
          <div class="col-auto">
            <label class="text-info" style="font-weight: bold">{{
              loadedBookingInfo.booking.tourtype_booking
            }}</label>
          </div>
        </div>
      </div>
      <div>
        <h4 class="text-warning text-end">
          <span>PHP</span> {{ formatValue(loadedBookingInfo.booking.amount_booking) }}
        </h4>
      </div>
    </div>
  </div>
  <div class="d-print-none">
    <input
      v-model="searchQuery"
      type="text"
      placeholder="Search..."
      class="form-control mb-3"
    />
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th @click="sortTable('fullname_guest')">
              Name
              <i :class="getSortIcon('fullname_guest')"></i>
            </th>
            <th @click="sortTable('gender_guest')">
              Gender
              <i :class="getSortIcon('gender_guest')"></i>
            </th>
            <th @click="sortTable('birthdate_guest')">
              Birth Date
              <i :class="getSortIcon('birthdate_guest')"></i>
            </th>
            <th @click="sortTable('type_guest')">
              Type
              <i :class="getSortIcon('type_guest')"></i>
            </th>
            <th @click="sortTable('residence_guest')">
              Residence
              <i :class="getSortIcon('residence_guest')"></i>
            </th>
            <th @click="sortTable('price_guest')" class="text-end">
              Price
              <i :class="getSortIcon('price_guest')"></i>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="guest in sortedAndFilteredGuests" :key="guest.syspk_guest">
            <td>
              <a
                :href="'/guest/guestqr/' + guest.syspk_guest"
                @click.prevent="getQrCodeImage(guest.syspk_guest, event)"
                style="cursor: pointer"
              >
                <i class="bi bi-qr-code me-2" /> {{ guest.fullname_guest }}
              </a>
            </td>
            <td>{{ guest.gender_guest }}</td>
            <td>{{ guest.age_guest }}</td>
            <td>{{ guest.type_guest }}</td>
            <td>{{ guest.residence_guest }}</td>
            <td class="text-end">{{ formatValue(guest.price_guest) }}</td>
            <td>
              <div class="btn-group">
                <button
                  title="Edit"
                  @click.prevent="editGuest(guest)"
                  type="button"
                  class="btn btn-secondary btn-sm"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <button
                  title="Delete"
                  @click.prevent="deleteGuest(guest)"
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <TripTicket :id="currentBookingId" ref="printableContent" />

  <div class="modal" tabindex="-1" role="dialog" :class="{ show: showModal }">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Guest Information</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow-y: auto">
          <GuestForm
            v-if="isEdittingGuest"
            :selectedGuest="selectedGuest"
            :tourtype="loadedBookingInfo.booking.tourtype_booking"
            @update-successful="handleUpdateGuestSuccess"
          />
          <AddGuestQty
            v-if="isAdjustingQty"
            @slots-available="handleSlotsAvailable"
            :tourDate="loadedBookingInfo.booking.tourdate_booking"
            :tourType="loadedBookingInfo.booking.tourtype_booking"
            :currentQty="loadedBookingInfo.guests.length"
          />

          <AddGuestsForm
            v-if="isAddingGuest"
            @newguests-added="onNewGuestsAdded"
            :config="currentConfig"
          />
          <QRCodeImage v-if="isShowingQrCode" :id="currentId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { apiUrl, adminTellerRoles } from "@/utils/config";
import { useStore } from "vuex";
import axios from "axios";
import GuestForm from "@/components/GuestForm.vue";
import { formatDateString, formatNumber } from "@/utils/formatter";
import AddGuestQty from "@/components/AddGuestQty.vue";
import QRCodeImage from "@/components/QRCodeImage.vue";
import TripTicket from "@/components/TripTicket.vue";
import AddGuestsForm from "@/components/AddGuestsForm.vue";
export default defineComponent({
  name: "BookingInfo",
  components: {
    GuestForm,
    AddGuestQty,
    AddGuestsForm,
    QRCodeImage,
    TripTicket,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const loadedBookingInfo = ref({
      booking: {},
      guests: [],
    });
    const isAdjustingQty = ref(false);
    const isEdittingGuest = ref(false);
    const isAddingGuest = ref(false);
    const isShowingQrCode = ref(false);
    const currentId = ref(0);
    const currentBookingId = computed(() => props.id);
    const store = useStore();
    const costings = computed(() => store.state.costings);
    const selectedGuest = ref({
      fullname_guest: "",
    });
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const hasAdminTellerRoles = computed(() => {
      return currentUser.value.roles.some((role) => adminTellerRoles.includes(role));
    });
    const searchQuery = ref("");
    const sortKey = ref("");
    const sortOrder = ref(1); // 1 for ascending, -1 for descending

    const sortTable = (key) => {
      if (sortKey.value === key) {
        sortOrder.value = -sortOrder.value;
      } else {
        sortKey.value = key;
        sortOrder.value = 1;
      }
    };

    const getSortIcon = (key) => {
      if (sortKey.value === key) {
        return sortOrder.value === 1 ? "bi bi-arrow-down" : "bi bi-arrow-up";
      }
      return "";
    };
    const printableContent = ref(null);

    const printTripTicket = () => {
      if (printableContent.value && printableContent.value.getImage) {
        printableContent.value.getImage();
      } else {
        alert(currentBookingId.value);
      }
    };

    const onNewGuestsAdded = async () => {
      resetModal();
      await fetchData();
    };

    const sortedAndFilteredGuests = computed(() => {
      let guests = loadedBookingInfo.value.guests;

      if (searchQuery.value) {
        guests = guests.filter((guest) =>
          Object.values(guest)
            .join(" ")
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        );
      }

      if (sortKey.value) {
        guests.sort((a, b) => {
          if (a[sortKey.value] < b[sortKey.value]) {
            return -sortOrder.value;
          } else if (a[sortKey.value] > b[sortKey.value]) {
            return sortOrder.value;
          }
          return 0;
        });
      }

      return guests;
    });
    const handleUpdateGuestSuccess = async () => {
      await fetchData();
      showModal.value = false;
      isEdittingGuest.value = false;
    };
    const handleAddGuest = () => {
      showModal.value = true;
      isAdjustingQty.value = true;
    };
    const formatDate = (date) => {
      return formatDateString(date);
    };
    const formatValue = (number) => {
      return formatNumber(number);
    };
    const isSearching = ref(true);
    const isGettingGuestQrCode = ref(false);
    const fetchData = async () => {
      try {
        isSearching.value = true;
        const response = await axios.get(`${apiUrl}v1/b/getinfo/${props.id}`);
        loadedBookingInfo.value = response.data;
      } catch (error) {
        console.error("Error fetching pending bookings:", error);
      } finally {
        isSearching.value = false;
      }
    };

    const editGuest = (item) => {
      showModal.value = true;
      selectedGuest.value = {
        ...loadedBookingInfo.value.guests.find((x) => x.syspk_guest === item.syspk_guest),
      };
      selectedGuest.value.bookType = loadedBookingInfo.value.booking.tourtype_booking;
      selectedGuest.value.bookID = loadedBookingInfo.value.booking.syspk_booking;
      selectedGuest.value.birthdate_guest = formatDateString(
        selectedGuest.value.birthdate_guest
      );
      isEdittingGuest.value = true;
    };
    const deleteGuest = async (guest) => {
      try {
        const confirmed = await store.dispatch(
          "showConfirmModal",
          "Are you sure you want to delete this item?"
        );

        if (confirmed) {
          const response = await axios.post(
            `${apiUrl}v1/b/deleteguest/${loadedBookingInfo.value.booking.syspk_booking}/${guest.syspk_guest}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${store.getters["auth/currentUser"].accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.success) {
            store.dispatch("successToast", "Guest removed successfully!");
            await fetchData();
          } else {
            store.dispatch("errorToast", response.data.message);
          }
        }
      } catch (err) {
        store.dispatch("errorToast", "An error occured while processing the request.");
      }
    };
    const currentConfig = ref({ type: "", date: "", totalGuests: 0 });
    const showModal = ref(false);
    const handleSlotsAvailable = (qty) => {
      currentConfig.value = {
        type: loadedBookingInfo.value.booking.tourtype_booking,
        date: loadedBookingInfo.value.booking.tourdate_booking,
        totalGuests: qty,
        bookingId: loadedBookingInfo.value.booking.syspk_booking,
      };
      resetModal();
      isAddingGuest.value = true;
      showModal.value = true;
    };
    const getQrCodeImage = async (id, event) => {
      if (event) {
        event.preventDefault();
      }
      currentId.value = id;
      resetModal();
      isShowingQrCode.value = true;
      showModal.value = true;
    };

    const resetModal = () => {
      isAddingGuest.value = false;
      isAdjustingQty.value = false;
      isEdittingGuest.value = false;
      isShowingQrCode.value = false;
      showModal.value = false;
    };

    onMounted(async () => {
      await fetchData();
    });

    watch(
      () => selectedGuest.value, // Watching the reactive `selectedGuest` value
      (newValue) => {
        if (newValue) {
          let cost = costings.value.find(
            (x) =>
              x.tourpack_costing === loadedBookingInfo.value.booking.tourtype_booking &&
              x.guesttype_costing === newValue.type_guest &&
              x.residence_costing === newValue.residence_guest
          );
          if (cost.price_costing != newValue.price_guest) {
            selectedGuest.value.price_guest = cost.price_costing;
          }
        }
      },
      { deep: true }
    );

    return {
      isSearching,
      loadedBookingInfo,
      formatDate,
      formatValue,
      showModal,
      isGettingGuestQrCode,
      editGuest,
      selectedGuest,
      handleUpdateGuestSuccess,
      handleAddGuest,
      sortTable,
      getSortIcon,
      sortedAndFilteredGuests,
      searchQuery,
      isEdittingGuest,
      isAdjustingQty,
      handleSlotsAvailable,
      currentConfig,
      isAddingGuest,
      resetModal,
      onNewGuestsAdded,
      deleteGuest,
      currentId,
      getQrCodeImage,
      isShowingQrCode,
      printTripTicket,
      currentBookingId,
      printableContent,
      hasAdminTellerRoles,
    };
  },
});
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
</style>
