<template>
  <select class="form-select" v-model="selectedRange">
    <option :value="item.name" v-for="(item, index) in dateranges" :key="index">
      {{ item.value }}
    </option>
  </select>
  <form @submit.prevent="handleCustomSubmit" v-if="selectedRange === 'custom'">
    <div class="row">
      <div class="form-group col-lg-6">
        <label for="start">Start:</label>
        <input class="form-control" required type="date" id="start" v-model="start" />
      </div>
      <div class="form-group col-lg-6">
        <label for="end">End:</label>
        <input class="form-control" required type="date" id="end" v-model="end" />
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-success w-100">Apply</button>
      </div>
    </div>
  </form>
</template>
<script>
import { ref, watch } from "vue";
export default {
  props: {
    range: {
      type: String,
      required: true,
    },
  },
  emits: ["range-selected", "custom-range-selected"],
  setup(props, { emit }) {
    const dateranges = ref([
      { name: "today", value: "Today" },
      { name: "yesterday", value: "Yesterday" },
      { name: "thisweek", value: "This Week" },
      { name: "lastweek", value: "Last Week" },
      { name: "thismonth", value: "This Month" },
      { name: "lastmonth", value: "Last Month" },
      { name: "thisyear", value: "This Year" },
      { name: "lastyear", value: "Last Year" },
      { name: "custom", value: "Custom" },
    ]);
    const selectedRange = ref(props.range);
    const start = ref(new Date().toISOString().split("T")[0]); // Today's date
    const end = ref(new Date(Date.now() + 86400000).toISOString().split("T")[0]); // Tomorrow's date
    const handleCustomSubmit = async () => {
      emit("custom-range-selected", { start: start.value, end: end.value });
    };
    watch(selectedRange, (newValue) => {
      emit("range-selected", newValue);
    });

    return {
      dateranges,
      selectedRange,
      start,
      end,
      handleCustomSubmit,
    };
  },
};
</script>
