<template>
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
  </div>

  <!-- Content Row -->
  <DashboardCards />
  <!-- Content Row -->

  <div class="row">
    <!-- Area Chart -->
    <div class="col-xl-8 col-lg-7">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary" id="cal-header">
            {{ currentDateTime }} Bookings Calendar
          </h6>
          <div class="row">
            <div class="form-group col-lg-6">
              <select
                class="form-select"
                style="min-width: 100px"
                v-model="selectedMonth"
              >
                <option v-for="month in months" :key="month" :value="month.id">
                  {{ month.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <select style="min-width: 100px" class="form-select" v-model="selectedYear">
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="container">
            <div class="calendar table-responsive">
              <GuestsCalendar :year="selectedYear" :month="selectedMonth" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Column -->
    <div class="col-xl-4 col-lg-5">
      <!-- Project Card Example -->
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Recently Scanned Guests</h6>
        </div>
        <div id="ticketscontainer" class="card-body">
          <RecentlyScannedGuests />
        </div>
        <div class="card-footer">
          <a class="text-muted float-right" href="/Admin/ABooking/ConfirmedGuests"
            >View more</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import DashboardCards from "@/components/DashboardCards.vue";
import GuestsCalendar from "@/components/GuestsCalendar.vue";
import RecentlyScannedGuests from "@/components/RecentlyScannedGuests.vue";
export default {
  name: "AdminPage",
  components: {
    DashboardCards,
    GuestsCalendar,
    RecentlyScannedGuests,
  },
  setup() {
    const currentDateTime = computed(() => {
      const now = new Date();
      now.setHours(now.getHours() + 8); // Add 8 hours to UTC time
      return now.toLocaleString("en-US", { month: "long", year: "numeric" });
    });
    const currentYear = new Date().getFullYear();

    const selectedYear = ref(new Date().getFullYear());
    const selectedMonth = ref(new Date().getMonth() + 1);
    const months = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ];
    const startYear = 2022;
    const endYear = currentYear + 2;
    const years = ref([]);

    const store = useStore();
    onMounted(() => {
      for (let year = startYear; year <= endYear; year++) {
        years.value.push(year);
      }
      // Dispatch action to show admin header (adjust the action name as per your Vuex store setup)
      store.dispatch("showAdminHeader", true);
    });
    return {
      currentDateTime,
      months,
      selectedYear,
      selectedMonth,
      years,
    };
  },
};
</script>
