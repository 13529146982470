import apiService from "./apiService";
const guestService = {
    async getcheckedinguests(range){
        try {
            const result = await apiService.get(`v1/r/getcheckedinguests/${range}`, {}, true)
            return result.data;
        }
        catch {
            return [];
        }
    },
    async getcheckedinguestscustom(start,end){
        try {
            const result = await apiService.get(`v1/r/getcheckedinguests/custom`, {start:start,end:end}, true)
            return result.data;
        }
        catch {
            return [];}
    },
    async getQRCodeImage(id){
           return await apiService.get(`v1/g/getqrcodeimage/${id}`, {}, true)
    },
    async getGuestByQrCodeAsync(code){
        try{
            const result = await apiService.get("v1/g/getguestbycode",{code:code},true)
            return result.data;
        }
        catch{
           return  {success: false, message: "An error occured while processing the request."};
        }
    }
}

export default guestService;