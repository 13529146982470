<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h4>Island Capacity &amp; Settings</h4>
      <button class="btn btn-success mb-2" @click="addNew">
        <i class="bi bi-plus"></i> Create New
      </button>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead class="u-palette-1-base u-table-header u-block-542f-4">
          <tr style="height: 20px">
            <th class="u-table-cell u-block-542f-10">Date</th>
            <th class="u-table-cell u-block-542f-11">Type</th>
            <th class="u-table-cell u-block-542f-11">Day Tour Cap.</th>
            <th class="u-table-cell u-block-542f-12">Overnight Cap.</th>
            <th class="u-table-cell u-block-542f-12">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isProcessing">
            <td colspan="10" style="text-align: center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
          <tr v-for="item in items" :key="item.syspk_islcap">
            <td class="u-table-cell u-block-542f-16">
              <a
                href="#"
                @click.prevent="handleClick(item)"
                data-toggle="modal"
                data-target="#capEditModal"
                class="btn-islcap"
                >{{ toPhDate(item.date_islcap) }}</a
              >
            </td>

            <td class="u-table-cell u-block-542f-16">{{ item.type_islcap }}</td>

            <td class="u-table-cell u-block-542f-16">
              <a
                href="#"
                @click.prevent="handleClick(item)"
                data-toggle="modal"
                data-target="#capEditModal"
                class="btn-islcap"
                >{{ item.daytour_islcap }}</a
              >
            </td>

            <td class="u-table-cell u-block-542f-16">
              <a
                href="#"
                @click.prevent="handleClick(item)"
                data-toggle="modal"
                data-target="#capEditModal"
                class="btn-islcap"
                >{{ item.overnight_islcap }}</a
              >
            </td>
            <td class="u-table-cell u-block-542f-16">{{ item.note_islcap }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" :class="{ show: showModal }">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Schedule Information</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow-y: auto">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label class="col-form-label">Date:</label>
              <input
                type="date"
                class="form-control"
                v-model="currentSched.date_islcap"
                required
                name="date_islcap"
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">Day Tour Capacity</label>
              <input
                type="number"
                class="form-control"
                v-model="currentSched.daytour_islcap"
                required
                name="daytour_islcap"
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">Overnight Capacity:</label>
              <input
                type="number"
                class="form-control"
                v-model="currentSched.overnight_islcap"
                required
                name="overnight_islcap"
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">Notes:</label>
              <input
                type="text"
                class="form-control"
                v-model="currentSched.note_islcap"
                required
                name="note_islcap"
              />
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-danger w-100">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { formatDateString, formatPhDate } from "@/utils/formatter";
import toastService from "@/services/toastService";
import islandService from "@/services/islandService";
import spinnerService from "@/services/spinnerService";
export default {
  setup() {
    const items = ref([]);
    const isProcessing = ref(false);
    const showModal = ref(false);
    const modalAction = ref("");
    const currentSched = ref({});
    const toPhDate = (date) => {
      return formatPhDate(date);
    };
    const fetchData = async () => {
      try {
        isProcessing.value = true;
        const response = await islandService.get();
        items.value = response.data;
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        isProcessing.value = false;
      }
    };
    const handleSubmit = async () => {
      try {
        spinnerService.show();
        const response = await islandService.save(currentSched.value);
        if (response.data.success) {
          toastService.success("Action successful!");
          fetchData();
        } else {
          toastService.error(response.data.message);
        }
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    const addNew = () => {
      currentSched.value = { date_islcap: formatDateString(new Date()), syspk_islcap: 0 };
      resetModal();
      modalAction.value = "add";
      showModal.value = true;
    };
    const resetModal = () => {
      modalAction.value = "";
      showModal.value = false;
    };
    const handleClick = (item) => {
      currentSched.value = { ...item };
      currentSched.value.date_islcap = formatDateString(item.date_islcap);
      resetModal();
      modalAction.value = "edit";
      showModal.value = true;
    };
    onMounted(() => {
      fetchData();
    });
    return {
      toPhDate,
      items,
      isProcessing,
      resetModal,
      currentSched,
      handleClick,
      showModal,
      addNew,
      handleSubmit,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
</style>
