<template>
  <BookingsTable :status="status" :range="'thismonth'" />
</template>
<script>
import BookingsTable from "@/components/BookingsTable.vue";
import { ref } from "vue";
export default {
  name: "PendingBookings",
  components: {
    BookingsTable,
  },
  setup() {
    const status = ref("revoked");
    return {
      status,
    };
  },
};
</script>
