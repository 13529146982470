import apiService from "./apiService";
const manifestoService = {
    async getPendingPassengersAsync() {
        try {
            const result = await apiService.get(`v1/m/getpendingpassengers`, {}, true)
            return result.data;
        }
        catch {
            return [];
        }
    },
    async saveNew(manifesto) {
        const result = await apiService.post(`v1/m/savenewmanifesto`, manifesto, true);
        return result;
    },
    async update(manifesto) {
        const result = await apiService.post(`v1/m/updatemanifesto`, manifesto, true);
        return result;
    },
    async addMorePassengersAsync(id, passengers) {
        const result = await apiService.post(`v1/m/addmoreguests/${id}`, passengers, true);
        return result;
    },
    async removePassengerAsync(id, passId) {
        const result = await apiService.post(`v1/m/removeguest/${id}/${passId}`, true);
        return result;
    },
    async getPrintableManifesto(id) {
        try {
            return (await apiService.get(`v1/m/getprintable/${id}`, {},true)).data;
        }
        catch {
            return {};
        }
    }

}

export default manifestoService;