import apiService from "./apiService";
const boatendpoint = "v1/bt/"
const boatService = {
    async get() {
        try {
            const result = await apiService.get(`${boatendpoint}getboats`, {}, true)
            return result.data;
        }
        catch {
            return [];
        }
    },
    async save(boat) {
        try {
            const result = await apiService.post(`${boatendpoint}save`, boat, true);
            return result;
        }
        catch {
            return null;
        }
    },
}

export default boatService;