<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-group">
      <label>Select Boat</label>
      <select required v-model="newManifesto.boatId" class="form-select">
        <option
          v-for="boat in loadedBoats"
          :key="boat.syspk_boat"
          :value="boat.syspk_boat"
        >
          {{ boat.name_boat }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th >
                <div class="form-check" style="cursor: pointer;">
                    <input id="chkAll" style="scale: 1.5" type="checkbox" v-model="selectAll" @change="toggleSelectAll" />
                    <label for="chkAll" class="ms-2">Passengers</label>
                </div></th>
            </tr>
            <tbody>
                <tr v-for="passenger in newManifesto.passengers" :key="passenger.syspk_qrscan">
                    <td>
                        <div class="form-check">
                            <input style="scale: 1.5;" v-model="passenger.isselected"
                            @change="updateSelectAll" :id="passenger.syspk_qrscan" type="checkbox"/>
                            <label class="ms-2" :for="passenger.syspk_qrscan">{{ passenger.fullname_qrscan }}</label>
                        </div>
                    </td>
                </tr>
            </tbody>
          </thead>
        </table>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-danger w-100" type="submit">Save Changes</button>
    </div>
  </form>
</template>
<script>
import manifestoService from "@/services/manifestoService";
import { onMounted, ref } from "vue";
export default {
  props: {
    boats: {
      type: Object,
      required: true,
    },
  },
  emits: ["new-manifesto-added"],
  setup(props, { emit }) {
    const selectAll = ref(false);
    const newManifesto = ref({
      boatId: 10,
      passengers: [],
    });
    const loadedBoats = ref(props.boats);
    const handleSubmit = async () => {
      //asd
      emit("new-manifesto-added",newManifesto.value);
    };
    const toggleSelectAll = ()=> {
      const isChecked = selectAll.value;
      newManifesto.value.passengers.forEach(passenger => {
        passenger.isselected = isChecked;
      });
    };
const updateSelectAll = ()=> {
      selectAll.value = newManifesto.value.passengers.every(passenger => passenger.isselected);
    };
    onMounted(async ()=>{
        newManifesto.value.passengers = await manifestoService.getPendingPassengersAsync();
    });

    return {
      handleSubmit,
      newManifesto,
      loadedBoats,
      selectAll,
      updateSelectAll,
      toggleSelectAll
    };
  },
};
</script>
