import store from "@/store";
import confirmationService from "./confirmationService";
import router from "@/router";
import apiService from "./apiService";
const userService = {
    async logout() {
        const confirmed = await confirmationService.show("Are you sure you want to logout?");
        if (confirmed) {
            store.dispatch("auth/logout");
            router.replace('login');
        }
    },
    async search(key) {
        try {
            const response = await apiService.get("v1/u/search", { key: key }, true);
            return response.data;
        }
        catch {
            return [];
        }
    },
    async updateUserRoles(user) {
        return await apiService.post(`v1/u/updateroles/${user.syspk_user}/${user.role_user}`, true);
    },
    async signup(user){
        return await apiService.post(`v1/u/submitsignup`,user,true);
    },
    async forgotpassword(email){
        return await apiService.post(`v1/u/forgotpassword`,{email: email},true);
    },
    async resetPassword(request){
        return await apiService.post(`v1/u/resetpassword`,request,true);
    }
}

export default userService;