<template>
  <form @submit.prevent="handleSubmitGuest">
    <div class="row">
      <div class="form-group mb-2">
        <label for="fullname">Guest Name</label>
        <input
          type="text"
          class="form-control"
          id="fullname"
          v-model.trim="currentGuest.fullname_guest"
        />
      </div>
      <div class="form-group mb-2 col-6">
        <label for="fullname">Gender</label>
        <select class="form-select" v-model="currentGuest.gender_guest">
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
      <div class="form-group mb-2 col-6">
        <label for="fullname">Age</label>
        <input class="form-control" type="number" v-model="currentGuest.age_guest" />
      </div>

      <div class="form-group mb-2 col-6">
        <label for="fullname">Gender</label>
        <select class="form-select" v-model="currentGuest.type_guest">
          <option value="International">International</option>
          <option value="Tourist">Tourist</option>
          <option value="Gov't Employee">Gov't Employee</option>
          <option value="Elem. Pupil">Elem. Pupil</option>
          <option value="College Student">College Student</option>
          <option value="HS Student">HS Student</option>
          <option value="Senior Cit.">Senior Cit.</option>
        </select>
      </div>

      <div class="form-group mb-2 col-6">
        <label for="fullname">Gender</label>
        <select class="form-select" v-model="currentGuest.residence_guest">
          <option value="Palomponganon">Palomponganon</option>
          <option value="Non-Palomponganon">Non-Palomponganon</option>
        </select>
      </div>

      <div class="form-group mb-2">
        <label for="cost">Fee</label>
        <input
          type="text"
          class="form-control text-end"
          id="cost"
          :value="formatValue(currentGuest.price_guest)"
        />
      </div>
      <div class="form-group mb-2">
        <button type="submit" class="btn btn-danger w-100">Save Changes</button>
      </div>
    </div>
  </form>
</template>
<script>
import { ref, watch, computed } from "vue";
import { apiUrl } from "@/utils/config";
import { formatDateString, formatNumber } from "@/utils/formatter";
import axios from "axios";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
export default {
  props: {
    selectedGuest: {
      type: Object,
      required: true,
    },
    tourtype: {
      type: String,
      required: true,
    },
  },
  emits: ["update-successful"],
  setup(props, { emit }) {
    const currentGuest = ref(computed(() => props.selectedGuest));
    const toast = useToast();
    const store = useStore();
    const costings = computed(() => store.state.costings);

    const formatValue = (number) => {
      return formatNumber(number);
    };

    const formatDate = (date) => {
      return formatDateString(date);
    };

    const handleSubmitGuest = async () => {
      try {
        store.dispatch("showSpinner");
        const response = await axios.post(`${apiUrl}v1/b/saveguest`, currentGuest.value);
        emit("update-successful", response.data);
        toast.success("Action successful!");
      } catch (error) {
        console.error("Error fetching pending bookings:", error);
        toast.error("An error occured while processing the request.");
      } finally {
        store.dispatch("hideSpinner");
      }
    };

    watch(
      () => currentGuest.value, // Watching the reactive `currentGuest` value
      (newValue) => {
        if (newValue) {
          let cost = costings.value.find(
            (x) =>
              x.tourpack_costing === props.tourtype &&
              x.guesttype_costing === newValue.type_guest &&
              x.residence_costing === newValue.residence_guest
          );
          if (cost.price_costing != newValue.price_guest) {
            currentGuest.value.price_guest = cost.price_costing;
          }
        }
      },
      { deep: true }
    );
    return {
      currentGuest,
      handleSubmitGuest,
      formatValue,
      formatDate,
    };
  },
};
</script>
