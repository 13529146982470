<template>
  <component :is="isAdminRoute ? AdminHeaderComponent : 'div'" />
  <component :is="SpinnerOverlayComponent" />
  <main v-if="!isAdminRoute" class="main">
    <RouterView />
  </main>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { defineAsyncComponent } from "vue";

// Get the current route
const route = useRoute();
const isAdminRoute = computed(() => route.path.startsWith("/admin"));

// Lazy load AdminHeader component
const AdminHeaderComponent = defineAsyncComponent(() =>
  import("@/components/AdminHeader.vue")
);
const SpinnerOverlayComponent = defineAsyncComponent(() =>
  import("@/components/SpinnerOverlay.vue")
);
</script>

<style scoped></style>
