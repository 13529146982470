<template>
  <GuestsTable :status="selectedStatus" />
</template>

<script>
import { computed } from "vue";
import GuestsTable from "@/components/GuestsTable.vue";

export default {
  components: {
    GuestsTable,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectedStatus = computed(() => props.status);
    return {
      selectedStatus,
    };
  },
};
</script>
