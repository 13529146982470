<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-group">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search Passengers"
        class="form-control mb-3"
      />
      <div class="form-group mb-3">
        <div v-if="selectedPassengers.length" class="">
          <span
            v-for="passenger in selectedPassengers"
            :key="passenger.syspk_qrscan"
            class="badge bg-secondary ps-1 me-2 d-inline-flex align-items-center"
          >
            {{ passenger.fullname_qrscan }}
            <button
              type="button"
              class="btn-close ms-1"
              aria-label="Close"
              @click="deselectPassenger(passenger)"
            ></button>
          </span>
        </div>
        <div v-else class="text-center">No selection yet.</div>
      </div>
      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th>
                <div class="form-check" style="cursor: pointer">
                  <input
                    id="chkAll"
                    style="scale: 1.5"
                    type="checkbox"
                    v-model="selectAll"
                    @change="toggleSelectAll"
                  />
                  <label for="chkAll" class="ms-2">Passengers</label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="passenger in filteredPassengers" :key="passenger.syspk_qrscan">
              <td>
                <div class="form-check">
                  <input
                    style="scale: 1.5"
                    v-model="passenger.isselected"
                    @change="updateSelectAll"
                    :id="passenger.syspk_qrscan"
                    type="checkbox"
                  />
                  <label class="ms-2" :for="passenger.syspk_qrscan">{{
                    passenger.fullname_qrscan
                  }}</label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-danger w-100" type="submit">Save Changes</button>
    </div>
  </form>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import manifestoService from "@/services/manifestoService"; // Make sure to adjust the import path
import toastService from "@/services/toastService";
import spinnerService from "@/services/spinnerService";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["new-passengers-added"],
  setup(props, { emit }) {
    const selectAll = ref(false);
    const searchQuery = ref("");
    const selectedId = computed(() => props.id);
    const currentManifesto = ref({
      passengers: [],
    });

    const handleSubmit = async () => {
      if (selectedPassengers.value.length === 0) {
        toastService.error("Please select guests to add");
        return;
      }
      try {
        spinnerService.show();
        const response = await manifestoService.addMorePassengersAsync(
          selectedId.value,
          selectedPassengers.value
        );
        if (response.data.success) {
          emit("new-passengers-added");
        } else {
          toastService.error(response.data.message);
        }
      } catch {
        toastService.error("An error occured while processing the request");
      } finally {
        spinnerService.hide();
      }
    };

    const toggleSelectAll = () => {
      const isChecked = selectAll.value;
      currentManifesto.value.passengers.forEach((passenger) => {
        passenger.isselected = isChecked;
      });
    };

    const updateSelectAll = () => {
      selectAll.value = currentManifesto.value.passengers.every(
        (passenger) => passenger.isselected
      );
    };

    const filteredPassengers = computed(() => {
      return currentManifesto.value.passengers.filter((passenger) => {
        return passenger.fullname_qrscan
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      });
    });

    const selectedPassengers = computed(() => {
      return currentManifesto.value.passengers.filter(
        (passenger) => passenger.isselected
      );
    });

    const deselectPassenger = (passenger) => {
      passenger.isselected = false;
      updateSelectAll();
    };

    onMounted(async () => {
      currentManifesto.value.passengers = await manifestoService.getPendingPassengersAsync();
    });

    return {
      handleSubmit,
      currentManifesto,
      selectAll,
      searchQuery,
      filteredPassengers,
      selectedPassengers,
      deselectPassenger,
      toggleSelectAll,
      updateSelectAll,
    };
  },
};
</script>
