<template>
  <BoatsTable />
</template>

<script>
import BoatsTable from "@/components/BoatsTable.vue";
export default {
  components: {
    BoatsTable,
  },
  setup() {},
};
</script>
