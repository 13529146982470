<template>
  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                Bookings (Today)
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                <router-link :to="{ name: 'approved' }">{{
                  dashboardInfo.totalBookings
                }}</router-link>
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-calendar fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                Expected Guests
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                <i class="fa fa-user-friends"></i>&nbsp;
                <router-link :to="{ name: 'guests', params: { status: 'expected' } }">{{
                  dashboardInfo.totalExpectedGuests
                }}</router-link>
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl col-md-6 mb-4">
      <div class="card border-left-info shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                Confirmed Guests
              </div>
              <div class="h5 mb-0 font-weight-bold">
                <i class="fa fa-qrcode fa" style="color: seagreen"></i>&nbsp;<router-link
                  :to="{ name: 'guests', params: { status: 'confirmed' } }"
                  >{{ dashboardInfo.totalConfirmedGuests }}</router-link
                >
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No Show Guest -->
    <div class="col-xl col-md-6 mb-4">
      <div class="card border-left-danger shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                No-show Guests
              </div>
              <div class="h5 mb-0 font-weight-bold">
                <i class="fa fa-qrcode fa" style="color: red"></i>&nbsp;
                <router-link :to="{ name: 'guests', params: { status: 'waiting' } }">{{
                  dashboardInfo.totalExpectedGuests - dashboardInfo.totalConfirmedGuests
                }}</router-link>
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl col-md-6 mb-4">
      <div class="card border-left-warning shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                Pending Requests
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">
                <router-link :to="{ name: 'pending' }">{{
                  dashboardInfo.totalPendings
                }}</router-link>
              </div>
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardService from "@/services/dashboardService";
import { ref, onMounted } from "vue";
export default {
  setup() {
    const dashboardInfo = ref({
      totalBookings: 0,
      totalExpectedGuests: 0,
      totalConfirmedGuests: 0,
      totalPendings: 0,
    });
    onMounted(async () => {
      //asd
      dashboardInfo.value = await dashboardService.get();
    });
    return {
      dashboardInfo,
    };
  },
};
</script>
