<template>
  <TheEmptyHeader />
  <section class="section">
    <div class="password-reset-container">
      <div
        v-if="!linkWasSent"
        class="d-flex align-items-center justify-content-center min-vh-100"
      >
        <div class="text-center p-4 bg-white rounded shadow-lg">
          <h2 class="mb-4">Forgot Your Password?</h2>
          <p class="mb-4">
            Enter your email address below to receive instructions on how to reset your
            password.
          </p>
          <form @submit.prevent="handleSubmit">
            <div class="form-group mb-4">
              <label class="text-start" for="email"
                ><i class="bi bi-envelope-fill"></i> Email</label
              >
              <input
                v-model.trim="email"
                class="form-control"
                id="email"
                type="email"
                placeholder="Enter your email"
                required
              />
            </div>
            <div v-if="errmsg" class="alert alert-danger mb-4 text-start" role="alert">
              {{ errmsg }}
            </div>
            <button type="submit" class="btn btn-primary">
              <i class="bi bi-box-arrow-in-right"></i> Reset Password
            </button>
          </form>
        </div>
      </div>
      <div v-else class="d-flex align-items-center justify-content-center min-vh-100">
        <div class="alert alert-success mb-4" role="alert">
          <div class="d-flex align-items-center">
            <i class="bi bi-check-circle-fill text-success fs-3 me-3"></i>
            <div>
              <p class="mb-0">A reset password link has been sent to your email.</p>
              <p class="mb-0">
                <small
                  >Check your inbox or spam folder and follow the instructions to reset
                  your password.</small
                >
              </p>
              <div class="text-center">
                <router-link
                  :to="{ name: 'login' }"
                  type="submit"
                  class="btn btn-primary mt-2"
                >
                  <i class="bi bi-box-arrow-in-right"></i> Goto Login Page
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TheEmptyHeader from "@/components/TheEmptyHeader.vue";
import userService from "@/services/userService";
import spinnerService from "@/services/spinnerService";
import { ref } from "vue";
export default {
  components: {
    TheEmptyHeader,
  },
  setup() {
    const email = ref("");
    const linkWasSent = ref(false);
    const errmsg = ref(null);
    const handleSubmit = async () => {
      try {
        spinnerService.show();
        errmsg.value = null;
        const response = await userService.forgotpassword(email.value);
        if (response.data.success) {
          linkWasSent.value = true;
        } else {
          errmsg.value = response.data.message;
        }
      } catch (err) {
        errmsg.value = "An error occured while processing the request.";
      } finally {
        spinnerService.hide();
      }
    };

    return {
      email,
      handleSubmit,
      errmsg,
      linkWasSent,
    };
  },
};
</script>
