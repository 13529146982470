<template>
  <TheEmptyHeader />
  <section class="section">
    <div class="container mt-2">
      <QRCodeImage :id="currentId" />
    </div>
  </section>
</template>
<script>
import TheEmptyHeader from "@/components/TheEmptyHeader.vue";
import QRCodeImage from "@/components/QRCodeImage.vue";
import { computed } from "vue";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    TheEmptyHeader,
    QRCodeImage,
  },
  setup(props) {
    const currentId = computed(() => props.id);

    return {
      currentId,
    };
  },
};
</script>
