<template>
  <ConfirmDialogModal />
  <header id="header" class="header d-flex align-items-center fixed-top d-print-none">
    <div
      class="container-fluid position-relative d-flex align-items-center justify-content-between"
    >
      <router-link
        :to="{ path: '/', hash: '#hero' }"
        class="logo d-flex align-items-center me-auto me-xl-0"
      >
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <!-- <img src="assets/img/logo.png" alt=""> -->
        <h1 class="sitename">
          <span class="d-none d-lg-inline sitename">I</span
          ><i class="bi bi-heart-fill text-danger" style="font-size: 2rem"></i
          ><span class="d-none d-lg-inline sitename">PALOMPON</span>
        </h1>
      </router-link>

      <router-link v-if="!currentUser" :to="{ name: 'login' }" class="btn btn-getstarted"
        >Sign-in</router-link
      >
      <div v-if="currentUser" class="btn btn-getstarted" @click.prevent="toggleDropdown">
        <a class="d-flex justify-content-between align-items-center text-light">
          <span class="me-2">{{ currentUser.fullname_user }}</span>
          <i class="bi bi-chevron-down fs-12" style="margin-top: 4px"></i>
        </a>

        <div v-if="dropdownOpen" class="dropdown-menu show">
          <router-link
            v-if="hasAdminTellerRoles"
            class="dropdown-item"
            :to="{ name: 'admin' }"
            ><i class="bi bi-shield"></i> Admin</router-link
          >
          <router-link :to="{ name: 'my-bookings' }" class="dropdown-item"
            ><i class="bi bi-book" /> My Bookings</router-link
          >
          <router-link :to="{ name: 'my-bookings' }" class="dropdown-item"
            ><i class="bi bi-person" /> My Profile</router-link
          >
          <div class="dropdown-divider"></div>
          <a @click.prevent="logout" class="dropdown-item" href="#"
            ><i class="bi bi-power"></i> Logout</a
          >
          <!-- Add more dropdown items as needed -->
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { useStore } from "vuex";
import router from "@/router";
import { adminTellerRoles } from "@/utils/config";
import { computed, ref } from "vue";
import ConfirmDialogModal from "@/components/ConfirmDialog.vue";
export default {
  components: {
    ConfirmDialogModal,
  },
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const hasAdminTellerRoles = computed(() => {
      return currentUser.value.roles.some((role) => adminTellerRoles.includes(role));
    });
    const dropdownOpen = ref(false);
    const logout = async () => {
      try {
        await store.dispatch("auth/logout");
        router.push({ name: "login" });
      } catch (error) {
        console.log(error);
      }
    };
    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };
    return { currentUser, logout, dropdownOpen, toggleDropdown, hasAdminTellerRoles };
  },
};
</script>
<style scoped>
.custom-dropdown-toggle {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  background-color: #007bff; /* Example background color */
  color: #fff; /* Example text color */
  text-decoration: none; /* Remove underline */
  border-radius: 0.25rem; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
}

.custom-dropdown-toggle:hover {
  background-color: #0056b3; /* Example hover background color */
}

.custom-dropdown-icon {
  display: inline-block;
  width: 1.2rem; /* Adjust icon size */
  height: 1.2rem;
  vertical-align: middle;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px); /* Position below the button */
  left: calc(100% - 15rem);
  z-index: 1000;
  display: block;
  min-width: 15rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}
</style>
