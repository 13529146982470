<template>
  <div class="d-print-none">
    <div class="row mb-4 fw-bold">
      <div class="col-lg-6">
        <div class="my-1">
          <i class="bi bi-stoplights-fill"></i> {{ loadedManifesto.manifesto.name_boat }}
        </div>
        <div class="my-1">
          <i class="bi bi-person-fill"></i> {{ loadedManifesto.manifesto.fullname_user }}
        </div>
      </div>
      <div class="col-lg-6 text-lg-end">
        <div class="my-1">
          <i class="bi bi-calendar-fill"></i>
          {{ toPhDate(loadedManifesto.manifesto.date_manifesto) }}
        </div>
        <div class="my-1 fw-bold">
          <i class="bi bi-people"></i>
          {{ loadedManifesto.manifesto.totalpassenger_manifesto }}
        </div>
      </div>
    </div>
    <div class="text-end mb-2">
      <div class="btn-group">
        <ManifestoPrintable
          @printpage-available="print"
          :id="loadedManifesto.manifesto.syspk_manifesto"
        />

        <button @click.prevent="addClick" type="button" class="btn btn-success">
          <i class="bi bi-plus"></i> Add
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Passenger</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Age</th>
            <th>Contact</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="passenger in loadedManifesto.passengers"
            :key="passenger.syspk_qrscan"
          >
            <td>{{ passenger.fullname_qrscan }}</td>
            <td>{{ passenger.gender_qrscan }}</td>
            <td>{{ passenger.address_qrscan }}</td>
            <td>{{ getAge(passenger.birth_qrscan) }}</td>
            <td>{{ passenger.contactno_qrscan }}</td>
            <td>{{ toPhDate(passenger.date_qrscan) }}</td>
            <td>
              <div class="btn-group">
                <button
                  @click.prevent="removeFromManifesto(passenger.syspk_qrscan)"
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" role="dialog" :class="{ show: showModal }">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Booking Information</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow-y: auto">
          <AddGuestToManifestoForm
            :id="loadedManifesto.manifesto.syspk_manifesto"
            @new-passengers-added="onPassengersAdded"
            v-if="modalAction === 'addnew'"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="printable-content">
    <img
      v-for="(page, index) in pages"
      :key="index"
      :src="'data:image/png;base64,' + page"
      :alt="'Page' + (index + 1)"
      class="page"
      :style="getItemStyle(index)"
    />
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import spinnerService from "@/services/spinnerService";
import { calculateAge, formatPhDateString } from "@/utils/formatter";
import { onMounted, ref, watch } from "vue";
import ManifestoPrintable from "@/components/printables/ManifestoPrintable.vue";
import AddGuestToManifestoForm from "@/components/AddGuestToManifestoForm.vue";
import confirmationService from "@/services/confirmationService";
import manifestoService from "@/services/manifestoService";
import toastService from "@/services/toastService";
export default {
  components: {
    ManifestoPrintable,
    AddGuestToManifestoForm,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const loadedManifesto = ref({
      manifesto: { date_manifesto: new Date() },
      passengers: [],
    });
    const pages = ref([]);
    const showModal = ref(false);
    const modalAction = ref("");
    const resetModal = () => {
      showModal.value = false;
      modalAction.value = null;
    };
    const getItemStyle = (index) => {
      const paddingIncrease = 4.5 * index; // Calculate incremental padding
      return {
        width: "1090px",
        height: "1580px",
        paddingTop: `${10 + paddingIncrease}px`,
      };
    };
    const addClick = () => {
      resetModal();
      modalAction.value = "addnew";
      showModal.value = true;
    };
    const onPassengersAdded = async () => {
      resetModal();
      await fetchData();
    };
    const fetchData = async () => {
      try {
        spinnerService.show();
        const response = await apiService.get(`v1/m/getinfo/${props.id}`);
        loadedManifesto.value = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        spinnerService.hide();
      }
    };
    const removeFromManifesto = async (guestid) => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to remove this passenger?"
      );
      if (confirmed) {
        try {
          await manifestoService.removePassengerAsync(
            loadedManifesto.value.manifesto.syspk_manifesto,
            guestid
          );
          let idx = loadedManifesto.value.passengers.findIndex(
            (x) => x.syspk_qrscan === guestid
          );
          if (idx > -1) {
            loadedManifesto.value.passengers.splice(idx, 1);
            loadedManifesto.value.manifesto.totalpassenger_manifesto =
              loadedManifesto.value.passengers.length;
            toastService.success("Passenger removed successfully!");
          }
        } catch (err) {
          console.error(err);
          toastService.error("An error occured while processing the request");
        } finally {
          spinnerService.hide();
        }
      }
    };
    const toPhDate = (date) => {
      return formatPhDateString(date);
    };
    onMounted(async () => {
      await fetchData();
    });
    const getAge = (birth) => {
      return calculateAge(birth);
    };
    const print = (p) => {
      pages.value = p;
      setTimeout(() => {
        window.print();
      }, 200);
    };
    watch(
      () => pages.value,
      () => {}
    );
    return {
      loadedManifesto,
      getAge,
      toPhDate,
      print,
      resetModal,
      addClick,
      modalAction,
      showModal,
      onPassengersAdded,
      removeFromManifesto,
      pages,
      getItemStyle,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.printable-content {
  display: none;
}

@media print {
  .printable-content {
    display: block;
    width: 210mm;
    /* A4 width */
    height: 297mm;
    /* A4 height */
    margin: 0;
    padding: 0;
    page-break-after: always;
  }

  .page {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    page-break-after: always;
  }

  /* Hide other elements during print */
  body * {
    visibility: hidden;
  }

  .printable-content,
  .printable-content * {
    visibility: visible;
  }

  .printable-content {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
