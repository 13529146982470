<template>
  <TheEmptyHeader />
  <section class="section">
    <div class="container p-2 rounded-2 bg-light" style="height: 100%">
      <div class="d-flex justify-content-between align-items-center">
        <h1 class="mb-0">My Bookings</h1>
        <div>
          <select class="form-select" v-model="selectedRange">
            <option :value="item.name" v-for="(item, index) in dateranges" :key="index">
              {{ item.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table align-middle">
          <thead>
            <tr>
              <th>Booking #</th>
              <th>Booked By</th>
              <th>Status</th>
              <th>Qty</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Added</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isSearching">
              <td colspan="9" style="text-align: center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-for="(item, index) in bookings" :key="index">
              <td style="min-width: 100px; width: 100px">
                <router-link
                  :to="{ name: 'my-booking-info', params: { id: item.syspk_booking } }"
                >
                  <i class="bi bi-pencil me-2" />{{ item.syspk_booking }}
                </router-link>
              </td>
              <td>{{ item.recordIndecies }}</td>
              <td>{{ item.status_booking }}</td>
              <td>{{ item.qty }}</td>
              <td>{{ item.tourtype_booking }}</td>
              <td>{{ formatDate(item.tourdate_booking) }}</td>
              <td>{{ item.tourTime }}</td>
              <td>{{ item.timeAgo }}</td>
              <td class="text-end">{{ formatNumber(item.amount_booking) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import axios from "axios";
import { apiUrl } from "@/utils/config";
import { formatDateString, formatNumber } from "@/utils/formatter";
import TheEmptyHeader from "@/components/TheEmptyHeader.vue";
import { useStore } from "vuex";
export default {
  name: "PendingBookings",
  components: {
    TheEmptyHeader,
  },
  setup() {
    const store = useStore();
    const bookings = ref([]);
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const dateranges = ref([
      { name: "today", value: "Today" },
      { name: "yesterday", value: "Yesterday" },
      { name: "thisweek", value: "This Week" },
      { name: "lastweek", value: "Last Week" },
      { name: "thismonth", value: "This Month" },
      { name: "lastmonth", value: "Last Month" },
      { name: "thisyear", value: "This Year" },
      { name: "lastyear", value: "Last Year" },
      { name: "custom", value: "Custom" },
    ]);
    const selectedRange = ref("thismonth");
    const isSearching = ref(true);
    const formatDate = (date) => {
      return formatDateString(date);
    };

    const fetchData = async (range) => {
      try {
        isSearching.value = true;
        const response = await axios.get(
          `${apiUrl}v1/b/getbystatus/mybookings/${range}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser.value.accessToken}`,
              "Content-Type": "application/json", // Optional: set the content type if needed
            },
          }
        );
        bookings.value = response.data; // Assuming response.data is an array of bookings
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized, attempting to refresh token or logout...");
          store.dispatch("auth/logout");
        } else {
          console.error("Error fetching pending bookings:", error);
          store.dispatch("errorToast", "Error fetching data.");
        }
      } finally {
        isSearching.value = false;
      }
    };

    onMounted(async () => {
      await fetchData(selectedRange.value);
    });

    watch(selectedRange, async (newValue, oldValue) => {
      console.log(`Selected range changed from ${oldValue} to ${newValue}`);
      await fetchData(newValue);
      // You can perform any other actions based on the selection change here
    });
    return {
      bookings,
      formatDate,
      formatNumber,
      isSearching,
      dateranges,
      selectedRange,
    };
  },
};
</script>
