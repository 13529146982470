import apiService from "./apiService";
const islandService = {
    async get() {
           return await apiService.get("v1/b/getislandcap", {  }, true);
    },
    async save(cap) {
        return await apiService.post("v1/b/saveislandcap", cap, true);
 },
}

export default islandService;