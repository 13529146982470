<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h3 :class="badgeClass">{{ status.toUpperCase() }}</h3>
      <div>
        <select class="form-select" v-model="selectedRange">
          <option :value="item.name" v-for="(item, index) in dateranges" :key="index">
            {{ item.value }}
          </option>
        </select>
      </div>
    </div>
    <input v-model="searchQuery" class="form-control mt-2" placeholder="Search..." />

    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th @click="sortTable('syspk_booking')">Booking #</th>
            <th @click="sortTable('recordIndecies')">Booked By</th>
            <th @click="sortTable('status_booking')">Status</th>
            <th @click="sortTable('qty')">Qty</th>
            <th @click="sortTable('tourtype_booking')">Type</th>
            <th @click="sortTable('tourdate_booking')">Date</th>
            <th @click="sortTable('tourTime')">Time</th>
            <th @click="sortTable('timeAgo')">Added</th>
            <th @click="sortTable('amount_booking')" class="text-end">Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isSearching">
            <td colspan="10" style="text-align: center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
          <tr v-for="(item, index) in sortedAndFilteredBookings" :key="index">
            <td>
              <router-link
                :to="{ name: 'booking-info', params: { id: +item.syspk_booking } }"
              >
                {{ item.syspk_booking }}
              </router-link>
            </td>
            <td>{{ item.recordIndecies }}</td>
            <td>{{ item.status_booking }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ item.tourtype_booking }}</td>
            <td>{{ formatDate(item.tourdate_booking) }}</td>
            <td>{{ item.tourTime }}</td>
            <td>{{ item.timeAgo }}</td>
            <td class="text-end">{{ formatNumber(item.amount_booking) }}</td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  @click="editBooking(item)"
                  class="btn btn-secondary btn-sm"
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <button
                  title="Approve"
                  @click.prevent="approveBooking(item)"
                  v-if="status === 'pending'"
                  type="button"
                  class="btn btn-success btn-sm"
                >
                  <i class="bi bi-check"></i>
                </button>
                <button
                  title="Reject"
                  @click.prevent="rejectBooking(item)"
                  v-if="status === 'pending'"
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-ban"></i>
                </button>
                <button
                  title="Revoke"
                  @click.prevent="revokeBooking(item)"
                  v-if="status === 'approved'"
                  type="button"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-ban"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" :class="{ show: showModal }">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Booking Information</h5>
            <button
              type="button"
              class="btn-close"
              @click="resetModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="max-height: 500px; overflow-y: auto">
            <EditBookingForm
              v-if="isEditting"
              :booking="selectedBooking"
              @booking-updated="handleBookingUpdated"
            />
            <RejectForm
              v-if="isRejecting"
              @reject-success="handleRejectSuccess"
              :bookingId="selectedBooking.syspk_booking"
            />
            <RevokeForm
              v-if="isRevoking"
              :bookingId="selectedBooking.syspk_booking"
              @revoke-success="handleRevokeSuccess"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import axios from "axios";
import { apiUrl } from "@/utils/config";
import { formatDateString, formatNumber } from "@/utils/formatter";
import { useStore } from "vuex";
import EditBookingForm from "@/components/EditBookingForm.vue";
import RejectForm from "@/components/RejectForm.vue";
import RevokeForm from "@/components/RevokeForm.vue";

export default {
  components: {
    EditBookingForm,
    RejectForm,
    RevokeForm,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    range: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const selectedBooking = ref({
      qty: 1,
      tourdate_booking: "",
      tourtype_booking: "Day Tour",
      syspk_booking: 0,
    });
    const isEditting = ref(false);
    const isRejecting = ref(false);
    const isRevoking = ref(false);
    const showModal = ref(false);
    const bookings = ref([]);
    const searchQuery = ref("");
    const sortKey = ref("");
    const sortAsc = ref(true);
    const dateranges = ref([
      { name: "today", value: "Today" },
      { name: "yesterday", value: "Yesterday" },
      { name: "thisweek", value: "This Week" },
      { name: "lastweek", value: "Last Week" },
      { name: "thismonth", value: "This Month" },
      { name: "lastmonth", value: "Last Month" },
      { name: "thisyear", value: "This Year" },
      { name: "lastyear", value: "Last Year" },
      { name: "custom", value: "Custom" },
    ]);
    const selectedRange = ref(props.range);
    const isSearching = ref(true);
    const badgeClass = computed(() => {
      switch (props.status) {
        case "pending":
          return "badge bg-secondary";
        case "approved":
          return "badge bg-success";
        case "rejected":
        case "revoked":
          return "badge bg-danger";
        default:
          return "badge bg-warning";
      }
    });

    const fetchData = async (range) => {
      try {
        isSearching.value = true;
        const response = await axios.get(
          `${apiUrl}v1/b/getbystatus/${props.status}/${range}`,
          {
            headers: {
              Authorization: `Bearer ${store.getters["auth/currentUser"].accessToken}`,
              "Content-Type": "application/json", // Optional: set the content type if needed
            },
          }
        );
        bookings.value = response.data; // Assuming response.data is an array of bookings
      } catch (error) {
        console.error("Error fetching pending bookings:", error);
      } finally {
        isSearching.value = false;
      }
    };

    const resetModal = () => {
      showModal.value = false;
      isEditting.value = false;
      isRejecting.value = false;
      isRevoking.value = false;
    };

    const editBooking = (booking) => {
      isEditting.value = true;
      selectedBooking.value = { ...booking };
      selectedBooking.value.tourdate_booking = formatDateString(booking.tourdate_booking);
      showModal.value = true;
    };

    const approveBooking = async (booking) => {
      const confirmed = await store.dispatch(
        "showConfirmModal",
        "Are you sure you want to approve this booking?"
      );

      if (confirmed) {
        try {
          store.dispatch("showSpinner");
          const response = await axios.post(
            `${apiUrl}v1/b/approve/${booking.syspk_booking}`,
            {}, // Empty object for the request body if there is no data to send
            {
              headers: {
                Authorization: `Bearer ${store.getters["auth/currentUser"].accessToken}`,
                "Content-Type": "application/json", // Optional: set the content type if needed
              },
            }
          );
          if (response.data.success) {
            resetModal();
            store.dispatch("successToast", "Booking approved successfully");
            removeItem(booking.syspk_booking);
          } else {
            store.dispatch("errorToast", response.data.message);
          }
        } catch (err) {
          console.log(err);
          store.dispatch("errorToast", "An error occurred while processing the request");
        } finally {
          store.dispatch("hideSpinner");
        }
      }
    };

    const rejectBooking = (booking) => {
      isRejecting.value = true;
      selectedBooking.value = { ...booking };
      console.log(selectedBooking.value);
      showModal.value = true;
    };

    const revokeBooking = (booking) => {
      isRevoking.value = true;
      selectedBooking.value = { ...booking };
      console.log(selectedBooking.value);
      showModal.value = true;
    };

    const removeItem = (id) => {
      let idx = bookings.value.findIndex((x) => x.syspk_booking === id);
      if (idx !== -1) {
        // Check if the item was found
        bookings.value.splice(idx, 1); // Remove 1 element at the found index
      }
    };

    const updateItem = (booking) => {
      let idx = bookings.value.findIndex(
        (x) => x.syspk_booking === booking.syspk_booking
      );
      if (idx !== -1) {
        // Check if the item was found
        let prevItem = { ...bookings.value[idx] };
        prevItem.amount_booking = booking.amount_booking;
        prevItem.tourdate_booking = booking.tourdate_booking;
        prevItem.tourtype_booking = booking.tourtype_booking;
        bookings.value[idx] = prevItem;
      }
    };

    const handleRejectSuccess = (id) => {
      removeItem(id);
      resetModal();
    };

    const handleRevokeSuccess = (id) => {
      removeItem(id);
      resetModal();
    };

    const handleBookingUpdated = (booking) => {
      updateItem(booking);
      resetModal();
    };

    const sortTable = (key) => {
      if (sortKey.value === key) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortKey.value = key;
        sortAsc.value = true;
      }
    };

    const sortedAndFilteredBookings = computed(() => {
      let sorted = [...bookings.value];
      if (sortKey.value) {
        sorted.sort((a, b) => {
          let result = 0;
          if (a[sortKey.value] < b[sortKey.value]) result = -1;
          if (a[sortKey.value] > b[sortKey.value]) result = 1;
          return sortAsc.value ? result : -result;
        });
      }
      return sorted.filter((booking) => {
        return (
          booking.syspk_booking.toString().includes(searchQuery.value) ||
          booking.recordIndecies
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          booking.status_booking
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          booking.tourtype_booking
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          formatDateString(booking.tourdate_booking).includes(searchQuery.value) ||
          booking.tourTime.includes(searchQuery.value) ||
          booking.timeAgo.includes(searchQuery.value) ||
          formatNumber(booking.amount_booking).toString().includes(searchQuery.value)
        );
      });
    });

    onMounted(async () => {
      await fetchData(selectedRange.value);
    });

    watch(selectedRange, async (newValue, oldValue) => {
      console.log(`Selected range changed from ${oldValue} to ${newValue}`);
      await fetchData(newValue);
      // You can perform any other actions based on the selection change here
    });

    return {
      bookings,
      formatDate: formatDateString,
      formatNumber,
      isSearching,
      dateranges,
      selectedRange,
      badgeClass,
      showModal,
      selectedBooking,
      editBooking,
      resetModal,
      approveBooking,
      isEditting,
      isRejecting,
      rejectBooking,
      handleRejectSuccess,
      isRevoking,
      revokeBooking,
      handleRevokeSuccess,
      handleBookingUpdated,
      searchQuery,
      sortTable,
      sortedAndFilteredBookings,
    };
  },
};
</script>

<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
th {
  cursor: pointer;
}
</style>
