<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h3>{{ selectedStatus.toUpperCase() }}</h3>
      <div>
        <DateRangeSelector :range="selectedRange" @range-selected="onRangeSelected" />
      </div>
    </div>
    <div class="table-responsive">
      <CustomTable :items="guests" :headers="headers" />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from "vue";
import apiService from "@/services/apiService";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import { formatPhDateString } from "@/utils/formatter";
import CustomTable from "@/components/GuestCustomTable.vue";
import store from "@/store";
export default {
  components: {
    DateRangeSelector,
    CustomTable,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const guests = ref([]);
    const isSearching = ref(false);
    const selectedStatus = computed(() => props.status);
    const selectedRange = ref("today");
    const headers = ref({
      fullname_qrscan: "Name",
      gender_qrscan: "Gender",
      address_qrscan: "Address",
      date_qrscan: "Date",
    });
    const formatPhDate = (date) => {
      return formatPhDateString(date);
    };
    const fetchData = async () => {
      try {
        store.dispatch("showSpinner");
        const response = await apiService.get(
          `v1/g/${props.status}/${selectedRange.value}`,
          {},
          true
        );
        guests.value = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        store.dispatch("hideSpinner");
      }
    };

    const onRangeSelected = async (range) => {
      selectedRange.value = range;
      await fetchData();
    };

    watch(selectedStatus, async () => {
      // Fetch data or perform any other action based on the new status
      await fetchData();
    });
    onMounted(async () => {
      await fetchData();
    });
    return {
      guests,
      selectedStatus,
      onRangeSelected,
      selectedRange,
      isSearching,
      formatPhDate,
      headers,
    };
  },
};
</script>
