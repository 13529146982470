<template>
  <div v-if="isProcessing" class="text-center">
    <!-- Spinner while loading -->
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else>
    <!-- Display image or error message -->
    <div v-if="isSuccessfull" class="text-center">
      <!-- Display image if successful -->
      <img
        :src="imageSrc"
        style="max-width: 430px; max-height: 450px; width: 430px; height: 450px"
        alt="Fetched Image"
        v-if="imageSrc"
      />
      <!-- Button to download image -->
      <br />
      <button
        class="btn btn-primary mt-2"
        style="max-width: 430px; width: 430px"
        @click="downloadImage"
        v-if="imageSrc"
      >
        <i class="bi bi-download"></i> Download Image
      </button>
    </div>
    <div v-else class="alert alert-danger">
      <!-- Display error message if failed -->
      {{ failedMsg }}
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed, watch } from "vue";
import guestService from "@/services/guestService";
import toastService from "@/services/toastService";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const imageSrc = ref("");
    const isSuccessfull = ref(false);
    const isProcessing = ref(false);
    const failedMsg = ref("");
    const currentName = ref("");
    const currentId = computed(() => props.id);
    const fetchImage = async () => {
      try {
        currentName.value = "";
        if (currentId.value === 0) return;
        isProcessing.value = true;
        const response = await guestService.getQRCodeImage(currentId.value);
        isSuccessfull.value = response.data.success;
        if (response.data.success) {
          imageSrc.value = response.data.qrCodeImage;
          currentName.value = response.data.message;
        } else {
          failedMsg.value = response.data.message;
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        toastService.error("An error occured while processing the request.");
      } finally {
        isProcessing.value = false;
      }
    };

    const downloadImage = () => {
      const link = document.createElement("a");
      link.href = imageSrc.value;
      link.download = `${currentName.value}.png`; // Specify a default file name and extension
      link.click();
    };

    onMounted(() => {
      fetchImage(currentId.value);
    });

    watch(currentId, () => {
      fetchImage(currentId.value);
    });

    return {
      imageSrc,
      downloadImage,
      isProcessing,
      isSuccessfull,
      failedMsg,
    };
  },
};
</script>
