<template>
  <div class="password-reset-container">
    <div class="d-flex align-items-center justify-content-center min-vh-100">
      <div v-if="!resetSuccess" class="text-center p-4 bg-white rounded shadow-lg">
        <div class="text-center mb-4" style="margin-top: -105px">
          <router-link to="/">
            <img src="/favicon.ico" class="rounded-circle" height="150" width="150" />
          </router-link>
        </div>
        <h2 class="mb-4">Reset Your Password</h2>
        <p class="mb-4">Please enter and confirm your new password below.</p>

        <form @submit.prevent="handleSubmit">
          <div class="form-group mb-4">
            <label for="password"
              ><i class="bi bi-shield-lock-fill"></i> New Password</label
            >
            <input
              v-model="request.newpassword"
              class="form-control"
              id="password"
              type="password"
              required
              minlength="8"
            />
          </div>
          <div class="form-group mb-4">
            <label for="confirmPassword"
              ><i class="bi bi-shield-lock-fill"></i> Confirm Password</label
            >
            <input
              v-model="request.confirmpassword"
              class="form-control"
              id="confirmPassword"
              type="password"
              minlength="8"
              required
            />
          </div>
          <div v-if="errmsg" class="alert alert-danger mb-4" role="alert">
            {{ errmsg }}
          </div>
          <button type="submit" class="btn btn-primary">
            <i class="bi bi-check-circle-fill"></i> Reset Password
          </button>
        </form>
      </div>
      <div v-else class="text-center p-4 bg-white rounded shadow-lg">
        <h2 class="mb-4">Password Reset Successful</h2>
        <p class="mb-4">Your password has been successfully reset.</p>
        <router-link :to="{ name: 'login' }" class="btn btn-primary mt-2">
          <i class="bi bi-box-arrow-right"></i> Go to Login Page
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import userService from "@/services/userService";
import spinnerService from "@/services/spinnerService";
export default {
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const request = ref({ key: props.code, newpassword: "", confirmpassword: "" });
    const errmsg = ref(null);
    const resetSuccess = ref(false);
    const handleSubmit = async () => {
      errmsg.value = null;
      if (request.value.newpassword !== request.value.confirmpassword) {
        errmsg.value = "Password mismatch.";
        return;
      }
      if (request.value.newpassword.length < 8) {
        errmsg.value = "Password must be atleast 8 characters";
        return;
      }
      try {
        spinnerService.show();
        const response = await userService.resetPassword(request.value);
        if (response.data.success) {
          //
          resetSuccess.value = true;
        } else {
          errmsg.value = response.data.message;
        }
      } catch (err) {
        errmsg.value = "An error occured while processing the request.";
      } finally {
        spinnerService.hide();
      }
    };
    return {
      handleSubmit,
      request,
      errmsg,
      resetSuccess,
    };
  },
};
</script>
