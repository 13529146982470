<template>
  <div>
    <table style="width: 100%" class="table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th v-for="dayOfWeek in daysOfWeek" :key="dayOfWeek">{{ dayOfWeek }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(week, index) in weeks" :key="index">
          <td
            style="min-width: 100px"
            v-for="day in week"
            :key="day.date"
            :class="day.class"
          >
            <template v-if="day.data">
              <strong>{{ day.data.colday }}</strong>
              <hr />
              <i class="bi bi-sun text-warning"></i>
              {{ day.data.dayTour }}<br />
              <i class="bi bi-moon text-white"></i>
              {{ day.data.overnight }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import dashboardService from "@/services/dashboardService";
export default {
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const calendarData = ref([]);
    const selectedYear = computed(() => props.year);
    const selectedMonth = computed(() => props.month);
    const getGuestCalendar = async (year, month) => {
      const data = await dashboardService.getGuestsCalendar(year, month);
      const daysInMonth = new Date(year, month, 0).getDate();
      calendarData.value = Array(daysInMonth).fill(null);
      data.forEach((item) => {
        calendarData.value[item.colday - 1] = item;
      });
    };

    onMounted(() => {
      getGuestCalendar(selectedYear.value, selectedMonth.value); // Example: July 2024
    });

    const weeks = ref([]);

    const generateCalendar = () => {
      const firstDay = new Date(selectedYear.value, selectedMonth.value - 1, 1).getDay();
      weeks.value = [[]];
      let currentWeek = 0;

      for (let i = 0; i < firstDay; i++) {
        weeks.value[currentWeek].push({ date: null, class: "" });
      }

      calendarData.value.forEach((item, index) => {
        if ((index + firstDay) % 7 === 0 && index !== 0) {
          currentWeek++;
          weeks.value[currentWeek] = [];
        }
        weeks.value[currentWeek].push({
          date: index + 1,
          class: item ? "table-active" : "",
          data: item,
        });
      });

      const emptyCells = 7 - ((calendarData.value.length + firstDay) % 7);
      if (emptyCells < 7) {
        for (let j = 0; j < emptyCells; j++) {
          weeks.value[currentWeek].push({ date: null, class: "" });
        }
      }
    };
    watch(selectedMonth, async () => {
      await getGuestCalendar(selectedYear.value, selectedMonth.value);
    });
    watch(selectedYear, async () => {
      await getGuestCalendar(selectedYear.value, selectedMonth.value);
    });
    // Watch calendarData for changes and regenerate calendar
    watch(calendarData, () => {
      generateCalendar();
    });

    return {
      daysOfWeek,
      weeks,
    };
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
