import apiService from "./apiService";
const dashboardService = {
    async get(){
        try {
            const result = await apiService.get(`v1/d/getdashboardinfo`, {}, true)
            return result.data;
        }
        catch {
            return {
                totalBookings: 0,
                totalExpectedGuests: 0,
                totalConfirmedGuests: 0,
                totalPendings: 0,
              };
        }
    },
    async getGuestsCalendar(year,month){
        try {
            const result = await apiService.get(`v1/d/getguestscalendar/${year}/${month}`, {}, true)
            return result.data;
        }
        catch {
            return [];
        }
    },
    async getRecentlyScannedGuests(){
        try {
            const result = await apiService.get(`v1/d/getrecentlyscannedguests`, {}, true)
            return result.data;
        }
        catch {
            return [];
        }
    }
}

export default dashboardService;