<template>
  <div
    v-for="item in items"
    :key="item.syspk_qrscan"
    class="card"
    style="margin-top: 2px"
  >
    <div class="card-header">
      <div class="row">
        <div class="col-8">
          <div class="row">
            <span class="text-success">{{ item.fullname_qrscan.toUpperCase() }}</span>
          </div>
        </div>
        <div class="col">
          <h6 class="float-right text-info">
            {{ getAge(item.birth_qrscan) }}
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="text-muted" style="font-size: smaller">{{ item.timeAgo }}</span>
        </div>
        <div class="col-8">{{ item.address_qrscan.toUpperCase() }}</div>
      </div>
      <div class="row">
        <div class="col">
          Booking # &nbsp;<router-link
            :to="{ name: 'booking-info', params: { id: Number(item.bookID) } }"
            >{{ item.bookID }}</router-link
          >
        </div>
        <div class="col">Date: {{ toPhDate(item.date_qrscan) }}</div>
        <div class="col">Tour: {{ item.bookType }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { calculateAge, formatPhDateString } from "@/utils/formatter";
import dashboardService from "@/services/dashboardService";
import { ref, onMounted } from "vue";
export default {
  setup() {
    const items = ref({});
    const getAge = (birth) => {
      return calculateAge(birth);
    };

    const toPhDate = (date) => {
      return formatPhDateString(date);
    };
    onMounted(async () => {
      items.value = await dashboardService.getRecentlyScannedGuests();
    });
    return {
      getAge,
      toPhDate,
      items,
    };
  },
};
</script>
