<template>
  <div class="row">
    <div class="col-lg-9">
      <h4>Reports</h4>
    </div>
    <div class="col-lg-3">
      <DateRangeSelector
        :range="'today'"
        @range-selected="rangeSelected"
        @custom-range-selected="customRangeSelected"
      />
    </div>
  </div>
  <div class="text-end">
    <button
      @click="exportToCSV"
      type="button"
      class="btn btn-primary my-2"
      v-if="guests.length !== 0"
    >
      <i class="bi bi-file-earmark-excel-fill"></i> Export Excel
    </button>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Gender</th>
          <th>Address</th>
          <th>Contact No</th>
          <th>Age</th>
          <th>Tour Date</th>
          <th>Tour Type</th>
          <th>Residence</th>
          <th>Classification</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isSearching">
          <td colspan="10" style="text-align: center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
        <tr v-for="guest in guests" :key="guest.syspk_qrscan">
          <td>{{ guest.fullname_qrscan }}</td>
          <td>{{ guest.gender_qrscan }}</td>
          <td>{{ guest.address_qrscan }}</td>
          <td>{{ guest.contactno_qrscan }}</td>
          <td>{{ guest.age }}</td>
          <td>{{ guest.date_qrscan }}</td>
          <td>{{ guest.tourtype_booking }}</td>
          <td>{{ guest.residence_guesttrans }}</td>
          <td>{{ guest.type_guesttrans }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import guestService from "@/services/guestService";
import { onMounted, ref } from "vue";
export default {
  components: {
    DateRangeSelector,
  },
  setup() {
    const guests = ref([]);
    const isSearching = ref(false);
    const rangeSelected = async (range) => {
      if (range !== "custom") {
        fetchData(range);
      }
    };
    const customRangeSelected = async (range) => {
      fetchCustomData(range);
    };
    const exportToCSV = () => {
      const csvContent = convertArrayOfObjectsToCSV(guests.value);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const currentDateTime = new Date().toISOString().replace(/[-T:]/g, "").slice(0, -5); // Generate current datetime in 'YYYYMMDDHHmm' format
      const filename = `export_${currentDateTime}.csv`;
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      link.click();
    };

    const convertArrayOfObjectsToCSV = (data) => {
      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(data[0]);

      let csv = "";
      csv += keys.join(columnDelimiter);
      csv += lineDelimiter;

      data.forEach((item) => {
        keys.forEach((key, index) => {
          if (index > 0) csv += columnDelimiter;
          let cellValue = item[key];
          // Handle cell values that contain commas
          if (typeof cellValue === "string" && cellValue.includes(",")) {
            cellValue = `"${cellValue}"`; // Wrap in double quotes
          }
          csv += cellValue;
        });
        csv += lineDelimiter;
      });

      return csv;
    };
    const fetchData = async (range) => {
      isSearching.value = true;
      guests.value = await guestService.getcheckedinguests(range);
      isSearching.value = false;
    };
    const fetchCustomData = async (range) => {
      isSearching.value = true;
      guests.value = await guestService.getcheckedinguestscustom(range.start, range.end);
      isSearching.value = false;
    };
    onMounted(() => {
      fetchData("today");
    });
    return {
      guests,
      rangeSelected,
      customRangeSelected,
      exportToCSV,
      isSearching,
    };
  },
};
</script>
