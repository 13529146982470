<template>
  <component :is="confirmDialogComponent" />
  <component :is="theHeaderComponent" />
  <component :is="theHeroSectionComponent" />
  <component :is="popularDestinationsComponent" />
  <component :is="pricingSectionComponent" />
  <component :is="theFooterComponent" />
</template>

<script setup>
// Lazy load components with names
import { defineAsyncComponent } from "vue";

const confirmDialogComponent = defineAsyncComponent(() =>
  import("@/components/ConfirmDialog.vue")
);
const theHeaderComponent = defineAsyncComponent(() =>
  import("@/components/TheHeader.vue")
);
const theHeroSectionComponent = defineAsyncComponent(() =>
  import("@/components/TheHeroSection.vue")
);
const popularDestinationsComponent = defineAsyncComponent(() =>
  import("@/components/PopularDestinations.vue")
);
const pricingSectionComponent = defineAsyncComponent(() =>
  import("@/components/PricingSection.vue")
);
const theFooterComponent = defineAsyncComponent(() =>
  import("@/components/TheFooter.vue")
);
</script>

<style scoped>
/* Add your scoped styles here */
</style>
