<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-group">
      <label>Name</label>
      <input required v-model="loadedBoat.name_boat" class="form-control" />
    </div>
    <div class="form-group">
      <label>Company/Owner</label>
      <input required v-model="loadedBoat.company_boat" class="form-control" />
    </div>
    <div class="form-group">
      <label>Company/Owner Contact #</label>
      <input required v-model="loadedBoat.companycontact_boat" class="form-control" />
    </div>
    <div class="form-group">
      <label>Captain</label>
      <input required v-model="loadedBoat.captain_boat" class="form-control" />
    </div>
    <div class="form-group">
      <label>Captain Contact #:</label>
      <input required v-model="loadedBoat.captaincontact_boat" class="form-control" />
    </div>
    <div class="form-group">
      <label>Capacity:</label>
      <input
        required
        v-model="loadedBoat.maxpassenger_boat"
        class="form-control text-end"
        type="number"
      />
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-danger w-100">Save Changes</button>
    </div>
  </form>
</template>
<script>
import { ref, computed } from "vue";
import toastService from "@/services/toastService";
import spinnerService from "@/services/spinnerService";
import boatService from "@/services/boatService";

export default {
  props: {
    boat: {
      type: Object,
      required: true,
    },
  },
  emits: ["boat-updated"],
  setup(props, { emit }) {
    const loadedBoat = ref(computed(() => props.boat));
    const handleSubmit = async () => {
      try {
        spinnerService.show();
        const response = await boatService.save(loadedBoat.value);
        if (response.data.success) {
          if (loadedBoat.value.syspk_boat === 0) {
            toastService.success("New boat added successfully!");
            emit("boat-added", response.data.boat);
          } else {
            toastService.success("Boat updated successfully!");
            emit("boat-updated", loadedBoat.value);
          }
        } else {
          toastService.error(response.data.message);
        }
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    return {
      handleSubmit,
      loadedBoat,
    };
  },
};
</script>
