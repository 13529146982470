<template>
  <form @submit.prevent="handleSubmit">
    <div class="input-group">
      <input required v-model="keyword" class="form-control" />
      <button type="submit" class="btn btn-primary">Search</button>
    </div>
  </form>

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Contact</th>
          <th>Roles</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isSearching">
          <td colspan="6" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
        <tr v-for="user in users" :key="user.syspk_user">
          <td>{{ user.fullname_user }}</td>
          <td>{{ user.email_user }}</td>
          <td>{{ user.mobile_user }}</td>
          <td>{{ user.role_user }}</td>
          <td>
            <div class="btn-group">
              <button @click="handleEdit(user)" class="btn btn-secondary btn-sm">
                <i class="bi bi-pencil"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal" tabindex="-1" role="dialog" :class="{ show: showModal }">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">User Information</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow-y: auto">
          <UserEditForm :user="selectedUser" @user-updated="onUserUpdated" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import userService from "@/services/userService";
import UserEditForm from "@/components/UserEditForm.vue";
export default {
  components: {
    UserEditForm,
  },
  setup() {
    const users = ref([]);
    const keyword = ref("");
    const modalAction = ref("");
    const showModal = ref(false);
    const isSearching = ref(false);
    const selectedUser = ref({ fullname_user: "", role_user: "client" });
    const fetchData = async () => {
      isSearching.value = true;
      users.value = await userService.search(keyword.value);
      isSearching.value = false;
    };
    const onUserUpdated = (user) => {
      let idx = users.value.findIndex((x) => x.syspk_user === user.syspk_user);
      if (idx > -1) {
        users.value[idx] = user;
      }
      resetModal();
    };
    const handleSubmit = () => {
      fetchData();
    };
    const handleEdit = (user) => {
      resetModal();
      modalAction.value = "edit";
      showModal.value = true;
      selectedUser.value = { ...user };
    };
    const resetModal = () => {
      showModal.value = false;
      modalAction.value = "";
    };
    onMounted(() => {
      fetchData();
    });
    return {
      users,
      handleSubmit,
      keyword,
      isSearching,
      handleEdit,
      showModal,
      resetModal,
      onUserUpdated,
      selectedUser,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
</style>
