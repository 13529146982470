<template>
  <button @click.prevent="print" type="button" class="btn btn-secondary">
    <i class="bi bi-printer"></i> Print
  </button>
</template>

<script>
import manifestoService from "@/services/manifestoService";
import spinnerService from "@/services/spinnerService";
import { ref } from "vue";
export default {
  props: {
    id: Number,
  },
  emits: ["printpage-available"],
  setup(props, { emit }) {
    const pages = ref(null);
    const print = async () => {
      spinnerService.show();
      pages.value = await manifestoService.getPrintableManifesto(props.id);
      emit("printpage-available", pages.value);
      spinnerService.hide();
    };
    return {
      pages,
      print,
    };
  },
};
</script>
