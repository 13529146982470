<template>
  <div class="row">
    <div class="col-lg-9">
      <h3>Manifesto</h3>
    </div>
    <div class="col-lg-3">
      <DateRangeSelector
        :range="selectedRange"
        @range-selected="onRangeSelected"
        @custom-range-selected="onCustomRangeSelected"
      />
    </div>
    <div class="text-right my-2">
      <button class="btn btn-success" @click.prevent="addNewClick">
        <i class="bi bi-plus"></i> Create New
      </button>
    </div>
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th>Id</th>
            <th>Added By</th>
            <th>Boat</th>
            <th>Passengers</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isSearching">
            <td colspan="10" style="text-align: center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
          <tr v-for="item in items" :key="item.sypsk_manifesto">
            <td>
              <router-link
                :to="{ name: 'manifesto-details', params: { id: item.syspk_manifesto } }"
              >
                {{ item.syspk_manifesto }}</router-link
              >
            </td>
            <td>{{ item.fullname_user }}</td>
            <td>{{ item.name_boat }}</td>
            <td>{{ item.totalpassenger_manifesto }}</td>
            <td>{{ item.date_manifesto }}</td>
            <td>
              <div class="btn-group">
                <button
                  @click.prevent="handleEditManifesto(item)"
                  type="button"
                  class="btn btn-secondary"
                >
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" role="dialog" :class="{ show: showModal }">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Booking Information</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow-y: auto">
          <NewManifesto
            :boats="boats"
            v-if="modalAction === 'addnew'"
            @new-manifesto-added="onNewManifestoAdded"
          />
          <EditManifesto
            :boats="boats"
            v-if="modalAction === 'edit'"
            :manifesto="selectedManifesto"
            @manifesto-updated="onManifestoEdited"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import apiService from "@/services/apiService";
import boatService from "@/services/boatService";
import toastService from "@/services/toastService";
import NewManifesto from "@/components/NewManifesto.vue";
import EditManifesto from "@/components/EditManifesto.vue";
import manifestoService from "@/services/manifestoService";
export default {
  components: {
    DateRangeSelector,
    NewManifesto,
    EditManifesto,
  },
  setup() {
    const selectedRange = ref("today");
    const items = ref([]);
    const boats = ref([]);
    const showModal = ref(false);
    const modalAction = ref(null);
    const isSearching = ref(false);
    const onRangeSelected = async (range) => {
      selectedRange.value = range;
      if (range !== "custom") {
        await fetchData();
      }
    };

    const resetModal = () => {
      showModal.value = false;
      modalAction.value = null;
    };

    const fetchData = async () => {
      try {
        isSearching.value = true;
        const response = await apiService.get(
          `v1/m/getmanifesto/${selectedRange.value}`,
          {},
          true
        );
        items.value = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        isSearching.value = false;
      }
    };

    const onNewManifestoAdded = async (manifesto) => {
      let newman = {
        boatId: manifesto.boatId,
        passengers: manifesto.passengers.filter((passenger) => passenger.isselected),
      };
      if (newman.passengers.length == 0) {
        toastService.error("No passenger selected.");
        return;
      }
      try {
        isSearching.value = true;
        const response = await manifestoService.saveNew(newman);
        if (response.data.success) {
          toastService.success("New manifesto added sucessfully!");
          resetModal();
          await fetchData();
        }
      } catch {
        toastService.error("An error occured while processing the request");
      } finally {
        isSearching.value = false;
      }
    };

    const onManifestoEdited = async () => {
      resetModal();
      fetchData();
    };

    const fetchCustomData = async (range) => {
      try {
        isSearching.value = true;
        const response = await apiService.get(
          `v1/m/getmanifesto/${selectedRange.value}`,
          { start: range.start, end: range.end },
          true
        );
        items.value = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        isSearching.value = false;
      }
    };
    const addNewClick = () => {
      //asd
      resetModal();
      showModal.value = true;
      modalAction.value = "addnew";
    };
    const selectedManifesto = ref(null);
    const handleEditManifesto = (item) => {
      resetModal();
      showModal.value = true;
      modalAction.value = "edit";
      selectedManifesto.value = { ...item };
    };

    const onCustomRangeSelected = async (range) => {
      await fetchCustomData(range);
    };

    onMounted(async () => {
      selectedRange.value = "today";
      await fetchData();
      boats.value = await boatService.get();
    });

    return {
      items,
      selectedRange,
      onRangeSelected,
      onCustomRangeSelected,
      addNewClick,
      showModal,
      modalAction,
      resetModal,
      onNewManifestoAdded,
      boats,
      isSearching,
      handleEditManifesto,
      onManifestoEdited,
      selectedManifesto,
    };
  },
};
</script>
<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}
</style>
