// services/apiService.js

import axios from 'axios';
import store from '@/store'; // Import your Vuex store if needed for authentication
import { apiUrl } from '@/utils/config';
// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || apiUrl,//'https://localhost:7179/', // Replace with your API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Centralized methods for HTTP requests
const apiService = {
  get(endpoint, params = {}, requiresAuth = true) {
    const config = {
      params,
      headers: {},
    };
    if (requiresAuth) {
      const token = store.getters['auth/currentUser']?.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return apiClient.get(endpoint, config);
  },

  post(endpoint, data, requiresAuth = true, config = {}) {
    if (requiresAuth) {
      const token = store.getters['auth/currentUser']?.accessToken;
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return apiClient.post(endpoint, data, config);
  },

  put(endpoint, data, requiresAuth = true, config = {}) {
    if (requiresAuth) {
      const token = store.getters['auth/currentUser']?.accessToken;
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return apiClient.put(endpoint, data, config);
  },

  delete(endpoint, requiresAuth = true, config = {}) {
    if (requiresAuth) {
      const token = store.getters['auth/currentUser']?.accessToken;
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return apiClient.delete(endpoint, config);
  },
};

export default apiService;
